//React
import React from 'react';

//Libraries
import { majorScale, minorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import Header from '../components/Header';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block'
import { CustomPieChart } from '../components/Recharts';

//Files
import { calcPercentage } from '../../../utils/functions';
import { aggregateFilterWeekSales } from '../../../utils/reports';


const CategorySales = ({ accountsData, sales, limit = true }) => {
  const salesDept = sales.salesDeptData
  const dSales = accountsData.categories.reduce((salesData, category) => {
    const catSales = aggregateFilterWeekSales(salesDept, 'weekSales', {categories: [category.name]});
    const catPrevSales = aggregateFilterWeekSales(salesDept, 'prevWeekSales', {categories: [category.name]});

    const variance = catPrevSales.weekSalesNet !== 0
      ? ((catSales.weekSalesNet - catPrevSales.weekSalesNet) / catPrevSales.weekSalesNet) * 100
      : (catSales.weekSalesNet > 0 ? 100 : 0); 
    
    const processed = {
      name: category.name,
      sales: calcPercentage(catSales.weekSalesNet, sales.net, 1),
      net: catSales.weekSalesNet,
      variance: variance.toFixed(2),
      id: category.id
    }
    return salesData.concat(processed);
  }, []);

  let final = _.sortBy(dSales, (s) => 1/s.net);
  //if (limit) final = final.slice(0, 4);

  const tableHeaders = [
    { label: 'Category Name', field: 'name', type: 'text', width: 5 },
    { label: 'Sales %', field: 'sales', type: 'text', suffix: '%', width: 3 },
    { label: 'Net Sales', field: 'net', type: 'numeric', prefix: '£', width: 3 },
    { label: 'LW Var %', descciption: 'vs Last week variance in %', field: 'variance', type: 'numeric', analytics: true, analyticsType: 'positive', suffix: '%', width: 2 }
  ]

  // Sort by net sales in descending order and limit to top 10
  let sortedSales = _.sortBy(dSales, (s) => -s.net);
  let top10 = sortedSales.slice(0, 10);
  
  // Calculate "Others" category
  let othersNet = sortedSales.slice(10).reduce((sum, item) => sum + item.net, 0);
  let othersSalesPercentage = calcPercentage(othersNet, sales.net, 1);
  
  if (othersNet > 0) {
    top10.push({
      name: 'Others',
      sales: othersSalesPercentage,
      net: othersNet,
      id: 'others'
    });
  }

  // Prepare data for pie chart
  const pieChartData = top10.map(item => ({
    name: item.name,
    value: item.net
  }));

  return (
    <Block width='100%'>
        <Header title='Sales by category' />
        <Pane height="500px" overflow="auto" marginBottom={majorScale(2)}>
          <DataTable
            headers={tableHeaders}
            items={final}
            listHeight={320}
          />
        </Pane>
        {/*<Pane width="100%">
          <CustomPieChart
            data={pieChartData}
            width="100%"
            height={500}
            labelFields={['name']}
            tooltipFields={['name', 'value']}
            tooltipTitles={['Category', 'Net Sales']}
            showLegend={true}
            labelPosition='outside'
          />
        </Pane>*/}
    </Block>
  )
}

export default CategorySales;
