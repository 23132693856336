//React
import React, { useMemo } from 'react';

//Components
import ReportFilters from './components/ReportFilters';
import { CustomCard } from './components/Recharts';

//Files
import { calcPercentage } from '../../utils/functions';

const Dashboard = ({ accountId, accounts, weekProps, filterProps }) => {

  const { accountsData, sales, weekdates } = accounts
  const data = useMemo(() => ({ accountId, accountsData, sales, weekdates, filterProps }),
  [accountId, accountsData, sales, weekdates, filterProps]);

  const newAccounts = {...accounts, filterProps }

  return (
    <>
      <ReportFilters accounts={newAccounts} />
      <CustomCard
         title='Gross Sales'
         data={[sales.gross, 'currency']}
         prevData={[sales.grossLw, 'currency']}
         infoData={[calcPercentage(sales.gross - sales.grossLw, sales.gross, 1), "percentage"]}
       />
    </>
  );
}

export default Dashboard;
