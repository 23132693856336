//React
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Table, Text, Pane } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';
import LoadingIndicator from '../../../components/IsLoading/IsLoading';

//Files
import { currency, shortDateDisplayFormat } from '../../../utils/format';
import { calcStocktakeCost, getStocktakeDate, reopenStocktake } from '../../../utils/functions';
import { current } from '../../../utils/selectors';

const StockTakeArchive = () => {
  const { accountId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const archive = useSelector((state) => {
    // Sort descending, by date
    let archive = _.reverse(_.sortBy(_.filter({ ...current(state, 'stockTakes', accountId) }, { 'isDraft': false }), (stocktake) => getStocktakeDate(stocktake)));
    // Calculate cost for each stockTake
    let mappedarchive = _.map(archive, (item) => {
      let cost = calcStocktakeCost(item);
      return { ...item, cost };
    });
    return mappedarchive;
  });

  useEffect(() => {
    // When suppliers are loaded (array is not empty), set isLoading to false
    if (archive.length > 0) {
      setIsLoading(false);
    }
  }, [archive]);

  //Handle reopening Stocktake
  const [selectedStocktake, setSelectedStocktake] = useState(null);
  const [isDialogShown, setIsDialogShown] = useState(false);

  const handleReopenStock = (stocktake) => {
    setSelectedStocktake(stocktake);
    setIsDialogShown(true);
  }

  const confirmReopen = () => {
    if (selectedStocktake) {
      reopenStocktake(accountId, selectedStocktake);
    }
    setIsDialogShown(false);
  };

  return (
    <Block>
      <CustomHeading level="3" marginY={majorScale(2)} paddingX={majorScale(2)}>Stocktakes</CustomHeading>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
      <Table>
        <Table.Head height={48}>
          <Table.TextHeaderCell>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Value</Table.TextHeaderCell>
          <Table.TextHeaderCell>Stocktake</Table.TextHeaderCell>
          <Table.TextHeaderCell>Report</Table.TextHeaderCell>
          <Table.TextHeaderCell>Re-open</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.isEmpty(archive) && (
            <Table.Row height={48}w>
              <Table.TextCell padding={majorScale(1)}>No Stocktake to display</Table.TextCell>
            </Table.Row>
          )}
          {_.map(archive, (stocktake) => (
            <Table.Row height={48} key={stocktake.id}>
              <Table.TextCell>{shortDateDisplayFormat(getStocktakeDate(stocktake))}</Table.TextCell>
              <Table.TextCell>£ {currency(stocktake.cost)}</Table.TextCell>
              <Table.Cell>
                <IconWrapper
                    is={Link}
                    to={`/${accountId}/review/stocktake/view/${stocktake.id}`}
                    name="clipboard"
                    appearance="clickable"
                />
              </Table.Cell>
              <Table.Cell>
                <IconWrapper
                    is={Link}
                    to={`/${accountId}/review/stocktake/report/${stocktake.id}`}
                    name="clipboard"
                    appearance="clickable"
                />
              </Table.Cell>
              <Table.Cell>
                <IconWrapper
                    // component={Link}
                    onClick={() => handleReopenStock(stocktake)}
                    name="edit"
                    appearance='danger'
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      )}

      <CustomDialog
        isOpen={isDialogShown}
        title="Confirm reopen Stocktake"
        onClose={() => setIsDialogShown(false)}
        onConfirm={confirmReopen}
        confirmLabel="Reopen Stocktake"
      >
        <Pane marginBottom={majorScale(4)}>
          <Text>Are you sure you want to reopen this stocktake?
          If prices have changed, this action will impact its total value!
          </Text>
        </Pane>
      </CustomDialog>
    </Block>
  );
};

export default StockTakeArchive;
