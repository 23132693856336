//React
import React from 'react';

//Libraries
import _ from 'lodash';
import { majorScale, Pane } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Accordion from '../../../components/ui/Accordion/Accordion';
import Block from '../../../components/ui/Block/Block'
import ValuesPane from '../../../pages/Stocks/components/ValuesPane';

const StockTakeDetailIngredients = ({ tableHeaders, mobileTableHeaders, totalHeaders, categoryOpt, ingredients, isMobile, filters, isOpen, setCategory }) => {

  // Filter ingredients based on category and check if all specified fields are zero
  const ingList = _.filter(ingredients, (ing) => (
    (categoryOpt.value === 'uncategorized' ? _.isEmpty(ing.categoryId) : _.includes(ing.categoryId, categoryOpt.value)) &&
    !(ing.unitStockAmount === 0 && ing.thisStockAmount === 0 && ing.wastageQuantity === 0 && ing.transferQuantity === 0 && ing.orderQuantity === 0 && ing.saleQuantity === 0)
  ));
  console.log(ingList, 'ingList')

  if (ingList.length === 0) {
    return null;
  }

  const toggleOpen = () => isOpen ? setCategory(null) : setCategory(categoryOpt.value);

  const calculateStocktakeTotals = (ingList) => {
    // Calculate each total by summing up the respective fields in ingList
    const totalUnitStockValue = _.sumBy(ingList, (ing) => parseFloat(ing.unitStockValue) || 0);
    const totalLivestockValue = _.sumBy(ingList, (ing) => parseFloat(ing.livestockValue) || 0);
    const totalThisStockValue = _.sumBy(ingList, (ing) => parseFloat(ing.thisStockValue) || 0);
    const totalVarValue = _.sumBy(ingList, (ing) => parseFloat(ing.varianceWorth) || 0);
  
  
    return {
      total: 'Total', // Display label for the totals row
      unitStockValue: totalUnitStockValue.toFixed(2),
      livestockValue: totalLivestockValue,
      thisStockValue: totalThisStockValue,
      totalVarValue: totalVarValue,
    };
  };
  
  return (
    <Block label={categoryOpt.label} marginBottom={majorScale(2)}>
      <Pane overflowY="hidden">
        <Pane
          //paddingTop={minorScale(1)}
          
          //paddingBottom={minorScale(1)}
          borderRadius={8}
          overflowY="auto"
          height="100%" 
        >
          {/* Main Data Table for Ingredients */}
          <DataTable
            items={ingList}
            filters={filters}
            headers={isMobile ? mobileTableHeaders : tableHeaders}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
            listHeight={400}
          />

          {/* Totals Data Table */}
          <DataTable
            items={[calculateStocktakeTotals(ingList)]}
            filters={filters}
            headers={totalHeaders}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
            listHeight={400}
            hideHeader={true}
            isTotals={true}
          />
        </Pane>
      </Pane>
    </Block>
  );
};

export default StockTakeDetailIngredients;
