//React
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, TextInputField, FormFieldLabel, SelectField, FormField, FilePicker, Checkbox, 
  Textarea, Table, Text, TextInput, Select, minorScale, useTheme } from 'evergreen-ui';

//Components
import EditModifierDialog from './RecipeEditFormComponents/EditModifierDialog';
import AddOns from '../../Modifiers/AddOns';
import MultiselectFilter from '../../../components/MultiselectFilter/MultiselectFilter';
import Button from '../../../components/ui/Button/Button';
import Block from '../../../components/ui/Block/Block';
import EditIngredientDialog from './RecipeEditFormComponents/EditIngredientDialog';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import { recipeNetPrice, recipeMargin, recipeCosting, recipeYieldPerPortion, recipeCostPerPortion,
  recipeIngredientAllergens, recipeWastePercentage, getRecipeItemDetails, getRecipeCalculatedFields } from '../../../utils/functions';
import { currency, toFixed } from '../../../utils/format';
import { messageTypes } from '../../../utils/constants';
import { current } from '../../../utils/selectors';

const maxInputWidth = 150;

const dietaryOptions = [
  'Gluten Free',
  'Vegetarian',
  'Vegan'
];


const RecipeEditForm = ({ accountId, recipe, onChange, onImgChange, errors, recipeId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const allergenList = useSelector((state) => state.allergens);
  const categoryList = useSelector((state) => current(state, 'recipeCategories', accountId));
  const departmentList = useSelector((state) => current(state, 'departments', accountId));
  
  const [editingIngredient, setEditingIngredient] = useState(false);
  const [removingIngredient, setRemovingIngredient] = useState(false);
  
  const [editingAddon, setEditingAddon] = useState(false);
  const [removingAddon, setRemovingAddon] = useState(false);

  const ingredientAllergens = recipeIngredientAllergens(recipe);
  //console.log(ingredientAllergens)
  const isReadonly = !!recipe.readonly;
  
  //Key function to update the recipe object every time a change is happening
  const onFieldChange = (field, newValue) => {
    const newRecipe = { 
      ...recipe, 
      [field]: newValue
    };
    onChange(newRecipe);
  };

  const isSuitable = (item) => {
    if (recipe && recipe.suitable) {
      return _.includes(recipe.suitable, item);
    }
    return false;
  };

  const toggleSuitability = (item, include) => {
    let suitability = recipe.suitable || [];
    if (include) {
      // Add suitability to the list
      suitability = _.uniq(_.concat(suitability, item));
    }
    else {
      // Remove suitability from the list
      suitability = _.without(suitability, item);
  }
  
  const newRecipe = { ...recipe, suitable: suitability };
    onChange(newRecipe);
  };

  const containsAllergen = (allergen) => {
    if (recipe && recipe.allergens) {
      return _.includes(recipe.allergens, allergen.name) || _.includes(recipe.allergens, allergen.id) || _.includes(ingredientAllergens, allergen.name);
    }
    return _.includes(ingredientAllergens, allergen.name);
  };

  const toggleAllergen = (item, include) => {
    let allergens = recipe.allergens || [];
    if (include) {
      // Add allergen to the list
      allergens = _.uniq(_.concat(allergens, item.name));
    }
    else if (!_.includes(ingredientAllergens, item.name)) {
      // Remove allergen from the list, but only if it's not an allergen in an ingredient
      allergens = _.without(allergens, item.name);
    }
    const newRecipe = { ...recipe, allergens };
    onChange(newRecipe);
  };

  const enforceNumeric = (value) => {
    // Allow only one dot and numeric characters
    const validValue = value.replace(/[^0-9.]/g, '');
    const dotIndex = validValue.indexOf('.');
  
    if (dotIndex !== -1) {
      // If a dot is found, limit to two decimal places
      const beforeDot = validValue.slice(0, dotIndex + 1);
      const afterDot = validValue.slice(dotIndex + 1).replace(/\./g, '').slice(0, 2);
      return beforeDot + afterDot;
    }
  
    return validValue;
  };  

  const addIngredient = (newIng) => {
    const editingIndex = editingIngredient.index;
    const listedIndex = _.findIndex(recipe.ingredients || [], { 'id': newIng.id });
    if (listedIndex >= 0 && editingIndex !== listedIndex) {
      // Ingredient is already part of the recipe!
      dispatch(actions.appMessageSet(`${newIng.name} is already in this recipe`, messageTypes.ERROR));
      return;
    }

    let ingredients;
    if (editingIndex >= 0) {
      // Splice the new ingredient info into the index being edited
      ingredients = _.concat(_.slice(recipe.ingredients, 0, editingIndex), newIng, _.slice(recipe.ingredients, editingIndex + 1));
    }
    else {
      // Add the ingredient to the list
      ingredients = _.concat(recipe.ingredients || [], newIng);
    }
    const newRecipe =  { ...recipe, ingredients };
    onChange(newRecipe);
  };

  const confirmRemove = (ingInfo) => {
    const ingredients = _.without(recipe.ingredients, ingInfo.item);
    const newRecipe = { ...recipe, ingredients };
    onChange(newRecipe);
  };

  const addAddon = (newIng) => {
    const editingIndex = editingAddon.index;
    const listedIndex = _.findIndex(recipe.addOns || [], { 'id': newIng.id });
    if (listedIndex >= 0 && editingIndex !== listedIndex) {
      // Add On is already listed!
      dispatch(actions.appMessageError(`${newIng.name} is already listed as an Add On for this recipe`));
      return;
    }

    let addOns;
    if (editingIndex >= 0) {
      // Splice the new Add On info into the index being edited
      addOns = _.concat(_.slice(recipe.addOns, 0, editingIndex), newIng, _.slice(recipe, editingIndex + 1));
    }
    else {
      // Append the Add On to the list
      addOns = _.concat(recipe.addOns || [], newIng);
    }
    const newRecipe = { ...recipe, addOns };
    onChange(newRecipe);
  };

  const confirmAddOnRemove = (ingInfo) => {
    const addOns = _.without(recipe.addOns, ingInfo.item);
    const newRecipe = { ...recipe, addOns };
    onChange(newRecipe);
  };

  const setDepartments = (name, value) => {
    if (!value || value === 'Select All') {
      const allDepartments = value === 'Select All' ? departmentList : [];
      onFieldChange(name, allDepartments);
      return;
    }
  
    const dep = departmentList.find(d => d.name === value);
    const departments = recipe.departments || [];
    const index = departments.findIndex(d => d.id === dep.id);
  
    if (index === -1 && dep) {
      departments.push(dep);
    } else {
      departments.splice(index, 1);
    }
  
    onFieldChange(name, departments);
  };
    
  const category = recipe.categoryId ?
    categoryList.find(c => c.id === recipe.categoryId) :
    recipe.category ?
    categoryList.find(c => c.name === recipe.category) :
    null;

  const setCategory = (name) => {
    const cat = categoryList.find(c => c.name === name);
    onFieldChange('category', name);
    onFieldChange('categoryId', cat?.id);
  }

  const calculateVariance = (targetGP, expectedGP) => {
    if (!targetGP || !expectedGP) return 0;
    const variance = expectedGP - targetGP;
    return variance;
  };  

  const handleRowSelect = (item) => {
    let path;
    const type = item.type || 'ingredient';

    switch (type) {
      case 'ingredient':
        path = `/ingredients/${item.id}`;
        break;
      case 'subrecipe':
        path = `/products/${accountId}/setup/recipes/${item.id}`;
        break;
      case 'modifier':
        path = `/products/${accountId}/setup/modifiers/${item.id}`;
        break;
      default:
        path = '/';
        break;
    }
    navigate(path);
  };

  const buildDepartmentsList = () => {
    const departments = departmentList.map(d => ({ value: d.name, label: d.name }));
    return [{ value: 'Select All', label: 'Select All' }, ...departments];
  };
  
  return (
    <>
      <EditIngredientDialog
          accountId={accountId}
          isShown={!!editingIngredient}
          existingIngredient={editingIngredient || null}
          onConfirm={addIngredient}
          onClose={() => {
            setEditingIngredient(false);
          }}
      />
      <EditModifierDialog
          accountId={accountId}
          isShown={!!editingAddon}
          existingIngredient={editingAddon || null}
          onConfirm={addAddon}
          onClose={() => {
            setEditingAddon(false);
          }}
      />
      <CustomDialog
          isOpen={!!removingIngredient}
          title="Delete ingredient"
          intent="danger"
          confirmLabel="Delete"
          onConfirm={(close) => {
            confirmRemove(removingIngredient);
            setRemovingIngredient(false);
          }}
          onClose={() => setRemovingIngredient(false)}
          onCloseComplete={() => setRemovingIngredient(false)}
      >
        <Pane marginBottom={majorScale(3)}>
        <Text>Are you sure you wish to remove {(removingIngredient.item) ? removingIngredient.item.name : 'ingredient'} from this recipe?</Text>
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!removingAddon}
          title="Remove Recipe Add On"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={() => {
            confirmAddOnRemove(removingAddon);
            setRemovingAddon(false);
          }}
          onClose={() => setRemovingAddon(false)}
          onCloseComplete={() => setRemovingAddon(false)}
      >
        <Pane marginBottom={majorScale(3)}>
        <Text>Are you sure you wish to remove {(removingAddon.item) ? removingAddon.item.name : 'ingredient'} from this recipe?</Text>
        </Pane>
      </CustomDialog>

      <Block
          display="flex"
          alignItems="stretch"
          marginBottom={majorScale(2)}
          padding={majorScale(2)}
      >
        <Pane flex="0 0 260px" marginRight={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
          <Pane
              flex="0 0 auto"
              height={200}
              width={200}
              backgroundPosition="center 0"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundImage={(recipe.main_imageURL) ? `url(${recipe.main_imageURL})` : 'none'}
              border="default"
              borderRadius={3}
              marginTop={majorScale(2)}
              marginBottom={majorScale(2)}
          />
          <FilePicker
              multiple={false}
              accept="image/*;capture=camera"
              onChange={(files) => onImgChange(files[0])}
              width={260}
          />
        </Pane>
        <Pane flex="1 1 0">
          <TextInputField
              label="Recipe name"
              placeholder="Recipe name"
              value={recipe.name || ''}
              onChange={(e) => onFieldChange('name', e.target.value)}
              isInvalid={!!errors.name}
              required
          />
          <Pane display="flex" alignItems='center'>
            <SelectField
                flex="1 1 0"
                width="33%"
                label="Recipe category"
                placeholder="Please select ..."
                value={category?.name || ''}
                onChange={(e) => setCategory(e.target.value)}
                isInvalid={!!errors.category}
                required
            >
              <option value=""></option>
              {categoryList.length === 0 ? (
                <option value="">No categories available. Please create one.</option>
              ) : (
                categoryList.map((category) => (
                  <option key={category.id} value={category.name}>{category.name}</option>
                ))
              )}
            </SelectField>

            <Pane display='flex' flexDirection='column' minHeight={90} width="33%" marginX={majorScale(2)}>
              <Text marginTop={minorScale(1)} marginBottom={minorScale(2)}>Departments</Text>
                <MultiselectFilter
                    setFilter={setDepartments}
                    selected={(recipe.departments || []).map(d => d.name)}
                    marginRight={majorScale(1)}
                    options={buildDepartmentsList()}
                    //label=
                    title='Departments'
                    name='departments'
                    width='100%'
                    backgroundColor='rgb(255,255,255) !important'
                    border='1px solid lightgrey !important'
                    required
                    isInvalid={!!errors.departments}
                />
            </Pane>

            <TextInputField
                flex="1 1 0"
                width="33%"
                label="PLU"
                placeholder="PLU"
                value={recipe.plu || ''}
                onChange={(e) => onFieldChange('plu', e.target.value)}
            />
            {/*<Checkbox
                label={'Hide recipe'}
                checked={!!recipe.archive}
                onChange={(e) => onFieldChange('archive', e.target.checked)}
                flexBasis="15%"
                marginX={majorScale(2)}
            />*/}
          </Pane>
          <FormField label="Description">
            <Textarea
                placeholder="Short description..."
                value={recipe.description || ''}
                onChange={(e) => onFieldChange('description', e.target.value)}
            />
          </FormField>
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <CustomHeading level="4" flex="1 0 auto">Ingredients</CustomHeading>
          <Button
              icon="add"
              appearance="primary"
              onClick={() => setEditingIngredient({})}
              disabled={isReadonly}
          >Add Ingredient</Button>
        </Pane>
        <Table>
          <Table.Head background={theme.colors.offwhite} paddingRight={0}>
            <Table.TextHeaderCell>Ing</Table.TextHeaderCell>
            <Table.TextHeaderCell >Qty</Table.TextHeaderCell>
            <Table.TextHeaderCell >UOM</Table.TextHeaderCell>
            <Table.TextHeaderCell>Measure (optional)</Table.TextHeaderCell>
            <Table.TextHeaderCell>Cost</Table.TextHeaderCell>
            <Table.TextHeaderCell >Extra</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>View</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>Del</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            { recipe.ingredients && recipe.ingredients.map((item, index) => {
              const itemDetails = getRecipeItemDetails(item, accountId);
              const calculatedFields = getRecipeCalculatedFields(item, itemDetails);
              const isModifier = item.type === 'modifier' && itemDetails.ingredients;
              return (
                <Fragment key={`ing_${index}`}>
                  <Table.Row 
                    borderBottom={isModifier ? '1px solid #edf0f2' : `1px solid ${theme.colors.secondary30}`}  
                  >
                    <Table.TextCell>{calculatedFields.name}</Table.TextCell>
                    <Table.TextCell >{item.quantity || calculatedFields.multiselect}</Table.TextCell>
                    <Table.TextCell >{calculatedFields.recipeUnit}</Table.TextCell>
                    <Table.TextCell>{item.measureQty} {item.measureUnit}</Table.TextCell>
                    <Table.TextCell>{toFixed(calculatedFields.cost, 4)}</Table.TextCell>
                    <Table.TextCell></Table.TextCell>
                    <Table.Cell flexBasis={56} flexGrow={0}>
                      <IconWrapper 
                        name="eye" 
                        appearance="clickable" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowSelect(item, 'ingredient')}
                        }
                        disabled={isReadonly} />
                    </Table.Cell>
                    <Table.Cell flexBasis={56} flexGrow={0}>
                      <IconWrapper 
                        name="edit" 
                        appearance="clickable" 
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingIngredient({ item, index })} 
                        }
                        disabled={isReadonly} />
                    </Table.Cell>
                    <Table.Cell flexBasis={56} flexGrow={0}>
                      <IconWrapper 
                        name="trash" 
                        appearance="danger" 
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemovingIngredient({ item, index })}
                        } 
                        disabled={isReadonly} />
                    </Table.Cell>
                  </Table.Row>
                  {
                    isModifier && itemDetails.ingredients.map((ing, itemIndex) => {
                      const details = getRecipeItemDetails(ing, accountId);
                      const calculatedFields = getRecipeCalculatedFields(ing, details);
                      const isLast = itemIndex === itemDetails.ingredients.length - 1;
                      return (
                        <Table.Row key={`mod_i_${itemIndex}`} borderBottom={isLast ? `1px solid ${theme.colors.secondary30}` : '1px solid #edf0f2'}>
                          <Table.TextCell>{calculatedFields.name}</Table.TextCell>
                          <Table.TextCell >{ing.quantity}</Table.TextCell>
                          <Table.TextCell >{calculatedFields.recipeUnit}</Table.TextCell>
                          <Table.TextCell></Table.TextCell>
                          <Table.TextCell
                              // flexBasis={150}
                              // flexGrow={0}
                              // justifyContent="flex-end"
                              // textProps={{ textAlign: 'right' }}
                          >{toFixed(calculatedFields.cost, 4)}</Table.TextCell>
                          <Table.TextCell>{ing.price}</Table.TextCell>
                          <Table.Cell flexBasis={56} flexGrow={0}>

                          </Table.Cell>
                          <Table.Cell flexBasis={56} flexGrow={0}>
                            
                          </Table.Cell>
                        </Table.Row>
                      )
                    }
                  )}
                </Fragment>
              );
            })}
            <Table.Row background={theme.colors.secondary10} height={48}>
              <Table.TextCell>Total</Table.TextCell>
              <Table.TextCell flexBasis={50} flexGrow={0}></Table.TextCell>
              <Table.TextCell flexBasis={100} flexGrow={0}></Table.TextCell>
              <Table.TextCell></Table.TextCell>
              <Table.TextCell>£ {currency(recipeCosting(recipe, true, true))}</Table.TextCell>
              <Table.TextCell flexBasis={56} flexGrow={0}></Table.TextCell>
              <Table.TextCell flexBasis={56} flexGrow={0}></Table.TextCell>
              <Table.TextCell flexBasis={56} flexGrow={0}></Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Block>


      <Block flex="1 1 100%" padding={majorScale(2)} marginBottom={majorScale(2)}>
        <CustomHeading level="4" marginBottom={majorScale(2)}>Price Breakdown</CustomHeading>
        <Pane 
          display="flex" 
          flexWrap="wrap" 
          justifyContent="space-between" 
          marginLeft={majorScale(2)}
        >

          {/* Column 1 */}
          <Pane flex="1 1 30%" marginRight={majorScale(2)} marginBottom={majorScale(2)}>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Gross price</FormFieldLabel>
              <TextInput
                placeholder="Gross price"
                value={recipe.menuprice || ''}
                onChange={(e) => onFieldChange('menuprice', enforceNumeric(e.target.value))}
                maxWidth={maxInputWidth}
                isInvalid={!!errors.menuprice}
              />
            </Pane>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>VAT %</FormFieldLabel>
              <TextInput
                placeholder="VAT"
                value={recipe.vat || ''}
                onChange={(e) => onFieldChange('vat', enforceNumeric(e.target.value))}
                maxWidth={maxInputWidth}
                isInvalid={!!errors.vat}
              />
            </Pane>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Net price</FormFieldLabel>
              <TextInput
                disabled
                value={currency(recipeNetPrice(recipe))}
                maxWidth={maxInputWidth}
              />
            </Pane>
          </Pane>

          {/* Column 2 */}
          <Pane flex="1 1 30%" marginRight={majorScale(2)} marginBottom={majorScale(2)}>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Ingredients cost</FormFieldLabel>
              <TextInput
                disabled
                value={currency(recipeCosting(recipe, true, true))}
                maxWidth={maxInputWidth}
              />
            </Pane>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Wastage %</FormFieldLabel>
              <TextInput
                placeholder="Wastage"
                value={recipe.wastage || ''}
                onChange={(e) => onFieldChange('wastage', enforceNumeric(e.target.value))}
                maxWidth={maxInputWidth}
                isInvalid={!!errors.wastage}
              />
            </Pane>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Total cost</FormFieldLabel>
              <TextInput
                disabled
                value={currency(recipeCosting(recipe, true, true) + (recipeCosting(recipe, true, true) * recipeWastePercentage(recipe)))}
                maxWidth={maxInputWidth}
              />
            </Pane>
          </Pane>

          {/* Column 3 */}
          <Pane flex="1 1 30%" marginRight={majorScale(2)} marginBottom={majorScale(2)}>
            {/* Expected GP % at the top */}
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Theoretical GP %</FormFieldLabel>
              <TextInput
                disabled
                value={recipeMargin(recipe, true).toFixed(2)}
                maxWidth={maxInputWidth}
              />
            </Pane>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
              marginBottom={majorScale(2)}
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Target GP %</FormFieldLabel>
              <TextInput
                placeholder="Target GP %"
                value={recipe.targetGP || ''}
                onChange={(e) => onFieldChange('targetGP', enforceNumeric(e.target.value))}
                maxWidth={maxInputWidth}
                isInvalid={!!errors.targetGP}
              />
            </Pane>
            <Pane
              display="flex"
              alignItems="center"
              //justifyContent="space-between"
            >
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Variance %</FormFieldLabel>
              <TextInput
                disabled
                value={`${calculateVariance(recipe.targetGP, recipeMargin(recipe, true)).toFixed(2)}%`}
                maxWidth={maxInputWidth}
                style={{
                  color: calculateVariance(recipe.targetGP, recipeMargin(recipe, true)) >= 0.01  ? theme.colors.success : theme.colors.danger,
                }}
              />
            </Pane>
          </Pane>
        </Pane>
      </Block>


      <Block flex="1 1 100%" padding={majorScale(2)} marginBottom={majorScale(2)}>
        <CustomHeading level="4" marginBottom={majorScale(2)}>Batch Breakdown</CustomHeading>
        <Pane 
          display="flex" 
          flexWrap="wrap" 
          justifyContent="space-between" 
          marginLeft={majorScale(2)}
        >

          {/* Column 1 */}
          <Pane flex="1 1 30%" marginRight={majorScale(2)} marginBottom={majorScale(2)}>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel width='120px' marginRight={majorScale(2)}>Yield* (1 by default)</FormFieldLabel>
              <TextInput
                placeholder="1"
                value={recipe.yield || ''}
                onChange={(e) => onFieldChange('yield', enforceNumeric(e.target.value))}
                maxWidth={maxInputWidth}
                isInvalid={!!errors.yield}
                required
              />
            </Pane>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel minWidth='120px' marginRight={majorScale(2)}>Cost/Yield</FormFieldLabel>
              <TextInput
                disabled
                value={currency(recipeCosting(recipe, false, true), 4)}
                maxWidth={maxInputWidth}
              />
            </Pane>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel width='120px' marginRight={majorScale(2)}>Yield/Portion</FormFieldLabel>
              <TextInput
                disabled
                value={toFixed(recipeYieldPerPortion(recipe), 2)}
                maxWidth={maxInputWidth}
              />
            </Pane>
          </Pane>

          {/* Column 2 */}
          <Pane flex="1 1 30%" marginRight={majorScale(2)} marginBottom={majorScale(2)}>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel width='120px' marginRight={majorScale(2)}>Yield Description*</FormFieldLabel>
              <Select
                value={recipe.yieldDescription || ''}
                onChange={(e) => onFieldChange('yieldDescription', e.target.value)}
                maxWidth={maxInputWidth}
                isInvalid={!!errors.yieldDescription}
                required
              >
                <option value=""></option>
                <option value="g">g</option>
                <option value="ml">ml</option>
                <option value="unit">unit</option>
              </Select>
            </Pane>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel width='120px' marginRight={majorScale(2)}>Wastage %</FormFieldLabel>
              <TextInput
                placeholder="Wastage"
                value={recipe.wastage || ''}
                onChange={(e) => onFieldChange('wastage', enforceNumeric(e.target.value))}
                maxWidth={maxInputWidth}
                isInvalid={!!errors.wastage}
              />
            </Pane>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel width='120px' marginRight={majorScale(2)}>Cost/Portion</FormFieldLabel>
              <TextInput
                disabled
                value={toFixed(recipeCostPerPortion(recipe), 5)}
                maxWidth={maxInputWidth}
              />
            </Pane>
          </Pane>

          {/* Column 3 */}
          <Pane flex="1 1 30%" marginRight={majorScale(2)} marginBottom={majorScale(2)}>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel width='120px' marginRight={majorScale(2)}>Portion (optional)</FormFieldLabel>
              <TextInput
                placeholder="Portion"
                value={recipe.portion || ''}
                onChange={(e) => onFieldChange('portion', enforceNumeric(e.target.value))}
                maxWidth={maxInputWidth}
              />
            </Pane>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
              <FormFieldLabel width='120px' marginRight={majorScale(2)}>Total cost</FormFieldLabel>
              <TextInput
                disabled
                value={currency(recipeCosting(recipe, true, true) + (recipeCosting(recipe, true, true) * recipeWastePercentage(recipe)))}
                maxWidth={maxInputWidth}
              />
            </Pane>
          </Pane>
        </Pane>
      </Block>

      <Block display="flex" marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane flex="1 1 0" marginRight={majorScale(2)}>
          <CustomHeading level="4" marginBottom={majorScale(2)}>Method</CustomHeading>
          <Textarea
              rows={8}
              placeholder="Recipe Method"
              value={recipe.method || ''}
              onChange={(e) => onFieldChange('method', e.target.value)}
          />
        </Pane>

        <Pane flex="1 1 0" marginLeft={majorScale(2)}>
          <CustomHeading level="3" marginBottom={majorScale(2)}>Notes</CustomHeading>
          <Textarea
              rows={8}
              placeholder="Recipe Notes"
              value={recipe.notes || ''}
              onChange={(e) => onFieldChange('notes', e.target.value)}
          />
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level="4" marginBottom={majorScale(2)}>Suitable For</CustomHeading>
        <Pane paddingLeft={majorScale(4)} display="flex">
          {dietaryOptions.map((item) => (
            <Checkbox
                key={_.kebabCase(item)}
                label={item}
                checked={isSuitable(item)}
                onChange={(e) => toggleSuitability(item, e.target.checked)}
                flexBasis="15%"
                marginRight={majorScale(2)}
            />
          ))}
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level="4" marginBottom={majorScale(2)}>Allergens</CustomHeading>
        <Pane
            paddingLeft={majorScale(4)}
            display="flex"
            flexFlow="row wrap"
        >
          {allergenList.map((allergen) => (
            <Checkbox
                key={allergen.id}
                label={allergen.name}
                checked={containsAllergen(allergen)}
                disabled={_.includes(ingredientAllergens, allergen.name)}
                onChange={(e) => toggleAllergen(allergen, e.target.checked)}
                flexBasis="15%"
                marginRight={majorScale(2)}
            />
          ))}
        </Pane>
      </Block>

      {/*<AddOns
        recipe={recipe}
        setRemovingAddon={setRemovingAddon}
        setEditingAddon={setEditingAddon}
        isReadonly={isReadonly}
        accountId={accountId}
      />
      <Button
          icon="add"
          marginBottom={majorScale(2)}
          marginX="auto"
          appearance="minimal"
          width="50%"
          minWidth={250}
          maxWidth={500}
          onClick={() => setEditingAddon({})}
          disabled={isReadonly}
      >Add On</Button>*/}
    </>
  )
};

RecipeEditForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  recipe: PropTypes.object,
  onChange: PropTypes.func,
  onImgChange: PropTypes.func,
};

RecipeEditForm.defaultProps = {
  recipe: {},
  onChange: () => {},
  onImgChange: () => {},
  errors: {},
};

export default RecipeEditForm;

