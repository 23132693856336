//React
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, minorScale, Pane, Text, Tablist, Paragraph, useTheme } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import StocktakeIngredients from './StocktakeIngredients';
import StocktakeRecipes from './StocktakeRecipes';
import StocktakeFilters from './StocktakeFilters';
import CustomHeading from '../../../components/Headings/Headings';
import Tab from '../../../components/ui/Tab/Tab'
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import { calcStocktakeCost, getStocktakeDate, recipeCosting, ingRecipePrice, findRecipeCategoryName } from '../../../utils/functions';
import { filterActiveIngredients } from '../../../utils/ingredients'; 
import { filterActiveRecipes } from '../../../utils/recipes'; 
import { TAB_INGREDIENTS, TAB_RECIPES } from '../../../utils/constants';
import { currency } from '../../../utils/format';
import { current } from '../../../utils/selectors';
import { buildExpectedStockValue } from '../../../utils/stock';


const filterEmptyIngredients = (ingredients) => {
  return _.filter(ingredients, (ing) => (ing.amount === '0' || !!ing.amount));
};

const buildIngredientCollection = (userIngredients) => {
  let ingList = _.keyBy(
    _.map(userIngredients, (ing) => ({ ...ing, recipeprice: ingRecipePrice(ing), 
      //worth: 0 
    })),
    'id'
  );
  return _.sortBy(ingList, 'name');
}

const buildRecipesCollection = (userRecipes) => {
  let list = _.keyBy(
    _.map(userRecipes, (recipe) => ({ ...recipe, recipeCosting: recipeCosting(recipe), 
      //worth: 0 
    })),
    'id'
  );
  return _.sortBy(list, 'name');
}

//TODO - review the starting and ending date for expected values
const StockTakeEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { accountId, stocktakeId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });

  const stockTake = useSelector((state) => {
    if (stocktakeId) {
      return _.find(current(state, 'stockTakes', accountId), { 'id': stocktakeId });
    }
    return false;
  });

  const [stockTakeInfo, setStockTakeInfo] = useState({});
  //console.log(stockTakeInfo)
  const [init, initForm] = useState(false)
  const [ingredients, setIngredients] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [confirmingSubmit, setConfirmingSubmit] = useState(false);

  const userIngredients = useSelector((state) => {
    const allIngredients = current(state, 'ingredients', accountId);
    return filterActiveIngredients(allIngredients);
  });

  const userRecipes = useSelector((state) => {
    const allRecipes = current(state, 'recipes', accountId);
    return filterActiveRecipes(allRecipes);
  });

  const recipeCategories = useSelector((state) => {
    return current(state, 'recipeCategories', accountId);
  });

  // Use stocktakeId for local storage key
  const stockForm = `stock_${accountId}_${stocktakeId || 'new'}`;
  console.log(stockForm, 'StockForm')

  const updateForm = useCallback((values) => {
    console.log('Updating local storage:', values);
    localStorage.setItem(stockForm, values ? JSON.stringify({ ingredients: values.ingredients, recipes: values.recipes }) : null);
  }, [stockForm])

  const resetForm = useCallback(() => {
    console.log('Resetting form and clearing local storage');
    setStockTakeInfo(stockTake || {})
    localStorage.removeItem(stockForm)
  }, [stockForm, stockTake, setStockTakeInfo])

  const mergeData = (firebaseData, localData) => {
    if (!localData) {
      return firebaseData;
    }
  
    // Get IDs of ingredients from local storage
    const localIngredientIds = new Set(localData.ingredients.map(ing => ing.id));
  
    // Filter out Firebase ingredients that are already in local storage
    const filteredFirebaseIngredients = firebaseData.ingredients.filter(ing => !localIngredientIds.has(ing.id));
  
    // Merge the local storage ingredients with the filtered Firebase ingredients
    const mergedIngredients = [...localData.ingredients, ...filteredFirebaseIngredients];
  
    // Get IDs of recipes from local storage
    const localRecipeIds = new Set(localData.recipes.map(recipe => recipe.id));
  
    // Filter out Firebase recipes that are already in local storage
    const filteredFirebaseRecipes = firebaseData.recipes.filter(recipe => !localRecipeIds.has(recipe.id));
  
    // Merge the local storage recipes with the filtered Firebase recipes
    const mergedRecipes = [...localData.recipes, ...filteredFirebaseRecipes];
  
    return {
      ...firebaseData,
      ingredients: mergedIngredients,
      recipes: mergedRecipes,
    };
  };
  

  useEffect(() => {
    if (init) return

    if (stockTake) {
      const localForm = localStorage.getItem(stockForm);
      if (localForm) {
        const localData = JSON.parse(localForm);
        const mergedData = mergeData(stockTake, localData);
        setStockTakeInfo(mergedData);
      } else {
        setStockTakeInfo(stockTake);
      }
      initForm(true);
    } else {
      const localForm = localStorage.getItem(stockForm);
      if (localForm) {
        setStockTakeInfo((prevInfo) => ({
          ...prevInfo,
          ingredients: JSON.parse(localForm).ingredients,
          recipes: JSON.parse(localForm).recipes,
        }));
      }
      initForm(true);
    }
  }, [stockTake, setStockTakeInfo, stockForm, init, initForm]);

  useEffect(() => {
    setIngredients(buildIngredientCollection(userIngredients))
    setRecipes(buildRecipesCollection(userRecipes))
  }, []);

  const stocktakeWorth = calcStocktakeCost(stockTakeInfo);

  const finaliseItems = (stocktake) => {
    const ingredientMap = _.keyBy(ingredients, 'id');
    const recipeMap = _.keyBy(recipes, 'id');
    const finalIngredients = _.map(stocktake.ingredients, (ing) => {
        const mergedIngredient = { ...ing, ...ingredientMap[ing.id] };
        return mergedIngredient;
    });

    const finalRecipes = _.map(stocktake.recipes, (recipe) => {
        const mergedRecipe = {
            ...recipe,
            ...recipeMap[recipe.id],
            category: findRecipeCategoryName(recipeMap[recipe.id], recipeCategories)
        };
        return mergedRecipe;
    });

    return {
        ...stocktake,
        ingredients: finalIngredients,
        recipes: finalRecipes
    };
  };  

  const saveCallback = () => {
    localStorage.removeItem(stockForm);
    navigate(`/${accountId}/inventory/stocktake`);
  };

  const saveDraft = () => {
    // Save Stock Take as draft
    const ingCollection = filterEmptyIngredients(stockTakeInfo.ingredients);
    if (stocktakeId) {
      dispatch(actions.stockTake.updateStockTake(accountId, { ...stockTakeInfo, ingredients: ingCollection, isDraft: true }, saveCallback));
    }
    else {
      dispatch(actions.stockTake.addStockTake(accountId, { ...stockTakeInfo, ingredients: ingCollection, isDraft: true }, saveCallback));
    }
    localStorage.removeItem(stockForm);
  };

  const saveSubmit = () => {
    // Save Stock Take and submit as final, redirecting to the stock take page when successful
    const ingCollection = filterEmptyIngredients(stockTakeInfo.ingredients);
    const stockTakeData = finaliseItems({ ...stockTakeInfo, ingredients: ingCollection }, ingredients, recipes);

    // Set the stocktake date to 00:00:00 UTC
    const stocktakeDate = moment.utc(getStocktakeDate(stockTakeInfo)).startOf('day').toDate();

    if (stocktakeId) {
      dispatch(actions.stockTake.updateStockTake(accountId, { ...stockTakeData, cost: stocktakeWorth, date: stocktakeDate, isDraft: false }, saveCallback));
    }
    else {
      dispatch(actions.stockTake.addStockTake(accountId, { ...stockTakeData, cost: stocktakeWorth, date: stocktakeDate, isDraft: false }, saveCallback));
    }
    localStorage.removeItem(stockForm);
  };

  useEffect(() => {
    if (init && !stockTake) {
      console.log('Updating form from useEffect:', stockTakeInfo);
      updateForm(stockTakeInfo);
    }
  }, [stockTakeInfo, updateForm, init, stockTake]);

  const date = moment.utc(getStocktakeDate(stockTakeInfo)).startOf('day').toDate();
  
  //TO CHECK
  const expectedStockValues = buildExpectedStockValue({ accountId, weekStart: moment.utc(date).subtract(1, 'week'), weekEnd: date })
  //console.log(expectedStockValues)

  return (
    <React.Fragment>
      <StocktakeFilters accountId={accountId} recipeCat={recipes[0] ? recipes[0].category : null}>
        {({
          handleSearch,
          handleRecipeSearch,
          filters,
          recipeSearch,
          updateFilters,
          filterFields,
          categoryOptions,
          currentTab,
          setCurrentTab,
          category,
          setCategory,
          setRecipeCategory
        }) => (
          <>
          <Block marginBottom={majorScale(2)} padding={majorScale(2)}>      
            <Pane
                display="flex"
                alignItems="center"
                marginBottom={majorScale(2)}
                flexFlow="row nowrap"
            >
              <Pane
                  flex={(isMobile) ? '1 0 100%' : '1 0 0'}
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  marginRight={majorScale(2)}
                  marginBottom={(isMobile) ? majorScale(2) : 0}
              >
                  <Pane 
                      display="flex"
                      alignItems="flex-start"
                      flexWrap="wrap" 
                      marginBottom={majorScale(2)} 
                  >
                  <CustomHeading level="3" marginRight={majorScale(2)} >Closing Date</CustomHeading>
                  <DatePickerInput
                      value={getStocktakeDate(stockTakeInfo)}
                      onChange={(newDate) => setStockTakeInfo((prevValues) => ({ ...prevValues, date: newDate }))}
                      inputProps={{ width: 'auto' }}
                  />
                  </Pane>
                  <Text
                      size={300}
                      maxWidth='400px'
                      //marginTop={majorScale(1)}
                      //marginBottom={majorScale(2)} 
                      textAlign="start"
                      color="warning"
                  >
                    Orders and Sales on or after this date will be included in the next reporting period.
                  </Text>
              </Pane>
              <Pane
                  display="flex"
                  justifyContent="flex-end"
                  //marginBottom={majorScale(2)}
                  flexFlow={1}
                  marginTop={0}
                  paddingTop={0}
              >
                <Button appearance="minimal" marginX={minorScale(2)} onClick={resetForm}>Reset</Button>
                <Button appearance="primary" marginX={minorScale(2)} onClick={() => saveDraft()}>Save Draft</Button>
                <Button appearance="danger" marginX={minorScale(2)} onClick={() => setConfirmingSubmit(true)}>Close Period</Button>
              </Pane>
            </Pane>

            <Pane
                flex="1 0 0"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
            >
                <CustomHeading level="4" marginX={majorScale(1)}>Actual Value</CustomHeading>
                <Text fontSize={isMobile ? 16 : 20} marginX={majorScale(2)} size={600} color={theme.colors.tertiary100}>£ {currency(stocktakeWorth)}</Text>
                <CustomHeading level="4" marginX={majorScale(1)}>vs Th. Value</CustomHeading>
                <Text fontSize={isMobile ? 16 : 20} marginX={majorScale(2)} size={600} color={theme.colors.tertiary100}>£ {currency(expectedStockValues.expectedStockValue)}</Text>
            </Pane>
          </Block>

          <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
            {currentTab === TAB_INGREDIENTS && (
              <FilterBar
                filterFields={filterFields}
                filters={filters}
                setFilter={updateFilters}
                searchPlaceholder="Search Ingredients"
                searchOnChange={handleSearch}
                marginBottom={majorScale(2)}
              />
            )}
            {currentTab === TAB_RECIPES && (
              <FilterBar
                searchPlaceholder="Search Recipes"
                searchOnChange={handleRecipeSearch}
                marginBottom={majorScale(2)}
              />
            )}
            <Tablist display="flex" flexShrink={0} overflowX="auto" style={{width: '30%'}}>
              <Tab
                isSelected={currentTab === TAB_INGREDIENTS}
                onSelect={() => setCurrentTab(TAB_INGREDIENTS)}
              >Ingredients</Tab>
              <Tab
                isSelected={currentTab === TAB_RECIPES}
                onSelect={() => setCurrentTab(TAB_RECIPES)}
              >Recipes</Tab>
            </Tablist>
          </Block>

          {currentTab === TAB_INGREDIENTS &&
            <StocktakeIngredients
              ingredients={ingredients}
              setStockTakeInfo={setStockTakeInfo}
              updateForm={updateForm}
              stockTakeInfo={stockTakeInfo}
              categoryOptions={categoryOptions}
              filters={filters}
              isMobile={isMobile}
              setCategory={setCategory}
              category={category}
            />
          }
          {
            currentTab === TAB_RECIPES &&
            <StocktakeRecipes
              recipes={recipes}
              setStockTakeInfo={setStockTakeInfo}
              updateForm={updateForm}
              stockTakeInfo={stockTakeInfo}
              recipeSearch={recipeSearch}
              isMobile={isMobile}
              setCategory={setRecipeCategory}
              category={category}
              recipeCategories={recipeCategories}
            />
          }
          <CustomDialog
              isOpen={confirmingSubmit}
              title="Confirm Close Period"
              intent="danger"
              onClose={() => setConfirmingSubmit(false)}
              onConfirm={() => {
                saveSubmit();
                setConfirmingSubmit(false);
              }}
          >
            <Paragraph 
              marginBottom={majorScale(2)}
              color={theme.colors.warning}
            >Have you uploaded all your Sales reports for the current period? Any missing Sales data may affect the accuracy of your report results.</Paragraph>
            <Paragraph marginBottom={majorScale(4)} >Are you sure you wish to close the period?</Paragraph>
          </CustomDialog>
        </>
      )}
      </StocktakeFilters>
    </React.Fragment>
  );
};

StockTakeEdit.propTypes = {
  accountId: PropTypes.string.isRequired,
  stockTakeId: PropTypes.string,
};

StockTakeEdit.defaultProps = {
  stockTakeId: '',
};

export default StockTakeEdit;
