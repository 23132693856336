//React
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table, Text } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import IconWrapper from '../../components/Icons/Icons';
import CustomHeading from '../../components/Headings/Headings';
import { CustomComposedChart } from '../../pages/HeadOfficeReports/components/Recharts';

//Files
import { calcStocktakeCost, getSalesForPeriod, getOrdersForPeriod, getStocktakeDate, calcPercentage, 
    periodTransferCosts, getProcurementTotal } from '../../utils/functions';
import { shortDateDisplayFormat, dateFormat } from '../../utils/format';
import { orderStatuses } from '../../utils/constants';
import { current, selectReportIncidentals } from '../../utils/selectors';
import { useDate } from '../../context/DateContext';


const calcGP = ({ openingStocktake, spending, closingStocktake, netSales }) => {
  return _.round(100 - calcPercentage((openingStocktake + spending - closingStocktake), netSales, 1), 1);
};

const ReportPeriods = () => {
  const { accountId } = useParams();
  const { calculateWeeks } = useDate();   // Updated to use setYearlyWeek
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);

  // Grab all approved stock takes, reverse sort by date, calculate the total 
  //  cost of each, and then key by date
  const stocktakes = useSelector((state) => 
    _.keyBy(
      _.map(
        _.reverse(
          _.sortBy(
            _.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }),
            (stocktake) => getStocktakeDate(stocktake)
          )
        ),
        (stocktake) => ({ ...stocktake, totalCost: calcStocktakeCost(stocktake) })
      ),
      (stocktake) => (dateFormat(getStocktakeDate(stocktake)))
    )
  );

  const stocktakeDates = _.keys(stocktakes);
  // Pair all the stocktake dates (open -> close), then filter out any empty items

  const periods = _.filter(_.map(stocktakeDates, (latestDate, index, arr) => {
    if (index === arr.length - 1) {
      return false;
    }
    return { start: arr[index + 1], end: dateFormat(moment.utc(latestDate).add(-1, 'day').toDate()) };
  }));
  
  // Grab all orders received between each stock take, calculate the total 
  //  cost, then key by the opening stock take date
  const orderTotals = useSelector((state) => {
    return _.keyBy(
      _.map(periods, (period) => {
        const start =  moment(period.start);
        const end = moment(period.end);
        const reportIncidentals = selectReportIncidentals({ state, accountId, weekStart: start.toDate() });
        const weekTransfers = periodTransferCosts(accountId, start, end);
        const weekOrders = getOrdersForPeriod(accountId, start.toDate(), end.toDate(), orderStatuses.RECEIVED);
        const procurementTotal = getProcurementTotal(weekOrders, weekTransfers, reportIncidentals)
        return { ...period, totalCost: procurementTotal };
      }),
      'start'
    );
  });

  const sales = useSelector((state) => {
    return _.keyBy(
      _.map(periods, (period) => {
        const periodSales = getSalesForPeriod(accountId, moment.utc(period.start), moment.utc(period.end));
        return { ...period, sales: _.reduce(periodSales, (info, salesInfo) => {
          return {
            totalcost: info.totalcost + salesInfo.totalcost,
            totalnet: info.totalnet + salesInfo.totalnet,
            totalgross: info.totalgross + salesInfo.totalgross
          };
        }, { totalcost: 0, totalnet: 0, totalgross: 0 }) };
      }),
      'start'
    );
  });

  /*const processChartData = useCallback(() => {
    const currentYear = moment().year();
    const weeks = calculateWeeks(currentYear);

    if (!Array.isArray(periods) || periods.length === 0) {
      console.error('Periods is not an array or is empty:', periods);
      setError('No valid periods data available');
      return [];
    }

    return periods
      .filter(period => period && period.start && period.end)
      .sort((a, b) => moment(a.start).diff(moment(b.start)))
      .map((period, periodIndex) => {
        try {
          const periodStart = moment(period.start);
          const periodEnd = moment(period.end);

          if (!stocktakes[period.start] || !orderTotals[period.start] || !sales[period.start]) {
            console.warn(`Missing data for period starting ${period.start}`);
            return null;
          }

          const gp = calcGP({
            openingStocktake: stocktakes[period.start].totalCost,
            spending: orderTotals[period.start].totalCost,
            closingStocktake: stocktakes[dateFormat(moment.utc(period.end).add(1, 'day').toDate())] ?
              stocktakes[dateFormat(moment.utc(period.end).add(1, 'day').toDate())].totalCost :
              null,
            netSales: sales[period.start].sales.totalnet
          });

          const expectedGP = calcPercentage(
            (sales[period.start].sales.totalnet - sales[period.start].sales.totalcost),
            sales[period.start].sales.totalnet,
            1
          );

          const varianceGP = gp - expectedGP;

          const periodWeeks = weeks.filter(week => 
            moment(week.start).isBetween(periodStart, periodEnd, null, '[]')
          );

          const firstWeek = moment(periodWeeks[0].start).week();
          const lastWeek = moment(periodWeeks[periodWeeks.length - 1].start).week();
          const periodLabel = periodWeeks.length > 1 ? `Weeks ${firstWeek}-${lastWeek}` : `Week ${firstWeek}`;

          return {
            key: `period-${periodIndex}`,
            period: periodLabel,
            actualSales: sales[period.start].sales.totalnet,
            actualCost: sales[period.start].sales.totalcost,
            gp,
            expectedGP,
            varianceGP,
            periodStart: periodStart.format('YYYY-MM-DD'),
            periodEnd: periodEnd.format('YYYY-MM-DD'),
            weeksCount: periodWeeks.length
          };
        } catch (error) {
          console.error(`Error processing period ${periodIndex}:`, error);
          return null;
        }
      })
      .filter(Boolean);
  }, [calculateWeeks, periods, sales, orderTotals, stocktakes]);

  useEffect(() => {
    try {
      const processedData = processChartData();
      console.log('Processed period data:', processedData);
      setChartData(processedData);
      setError(null);
    } catch (error) {
      console.error('Error in processChartData:', error);
      setError('An error occurred while processing the data');
    }
  }, [processChartData]);

  if (error) {
    return <Text color="danger">{error}</Text>;
  }*/

  return (
    <Page>
    <Block marginBottom={majorScale(2)}>
      <CustomHeading level="3" paddingX={majorScale(2)} marginY={majorScale(2)}>Report Periods</CustomHeading>
      {/*{chartData.length > 0 ? (
          <CustomComposedChart
            data={chartData}
            height={400}
            width="100%"
            layout="horizontal"
            xKey="period"
            yKey="actualSales"
            barKeys={['actualSales', 'actualCost']}
            lineKey="varianceGP"
            xType="category"
            yType="number"
            valueType="currency"
            yDomain={[0, 'auto']}
            y2Domain={[-20, 20]}
          />
        ) : (
          <Text>No data available for chart</Text>
        )}*/}
      <Table>
        <Table.Head height={48}>
          <Table.TextHeaderCell>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>To</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actual GP%</Table.TextHeaderCell>
          <Table.TextHeaderCell>Theoretical GP%</Table.TextHeaderCell>
          <Table.TextHeaderCell>View</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.isEmpty(periods) && (
            <Table.Row height={48}>
              <Table.TextCell>No reports to display</Table.TextCell>
            </Table.Row>
          )}
          {!_.isEmpty(periods) && _.map(periods, (period) => {
            const gp = calcGP({
              openingStocktake: stocktakes[period.start].totalCost,
              spending: orderTotals[period.start].totalCost,
              closingStocktake: stocktakes[dateFormat(moment.utc(period.end).add(1, 'day').toDate())] ?
                stocktakes[dateFormat(moment.utc(period.end).add(1, 'day').toDate())].totalCost :
                null,
              netSales: sales[period.start].sales.totalnet
            });
            const expectedGP = calcPercentage((sales[period.start].sales.totalnet - sales[period.start].sales.totalcost), sales[period.start].sales.totalnet, 1);
            return (
              <Table.Row height={48} key={period.start} isSelectable>
                <Table.TextCell>{shortDateDisplayFormat(period.start)}</Table.TextCell>
                <Table.TextCell>{shortDateDisplayFormat(period.end)}</Table.TextCell>
                <Table.TextCell>{gp} %</Table.TextCell>
                <Table.TextCell>{expectedGP} %</Table.TextCell>
                <Table.Cell>
                  <IconWrapper
                      name="clipboard"
                      appearance='clickable'
                      is={Link}
                      to={`/${accountId}/reports/gross-profit/${period.start}/${period.end}`}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Block>
    </Page>
  );
};

export default ReportPeriods;
