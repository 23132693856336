//React
import React, { useState, useEffect, useMemo } from 'react';

//Libraries
import { majorScale, Pane, SearchInput } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import { CustomCard } from '../components/Recharts'
import Block from '../../../components/ui/Block/Block'

//Files
import { currency } from '../../../utils/format';
import { getAccountOptionOrdersFromDate, getIngredientStockUOM } from '../../../utils/functions';
import { buildIngOptions, buildOptionCurrentPrice, buildOptionPrevWeekPrice,
  calculateTotalSpentOnIngredient, calculateTotalQuantity } from '../../../utils/reports';


const buildIngOptionPrices = ({ ing, optionList, accountsData, costs, accountId, weekdates, supplierMap, totalOrderValue, ...rest }) => {
  //Filter supplierOptions to be part of ingredient
  const ingsOpts = buildIngOptions(ing, optionList);

  //To convert ml -> L and g -> Kg
  const ingUom = getIngredientStockUOM(ing);
  const weekEnd = weekdates.end;

  // Collect all orders from all departments
  const allOrders = Object.values(costs.costsDeptData).flatMap(dept => dept.weekOrders || []);
  const allPrevWeekOrders = Object.values(costs.costsDeptData).flatMap(dept => dept.prevWeekOrders || []);
  
  let totalIngredientSpent = 0;
  let totalIngredientQuantity = 0;
  let totalIngredientQuantityAdjusted = 0;
  let totalPriceVarValue = 0; 

  const optsData = ingsOpts.map(opt => {
    //const lifeTimeOrders = _.reverse(getAccountOptionOrdersFromDate(accountsData, accountId, opt, moment(weekEnd)));
    // Get orders for the current option for the current and previous week
    const optOrders = allOrders.filter(order => 
      order.items.some(item => item.id === opt.id)
    );
    const optPrevWeekOrders = allPrevWeekOrders.filter(order => 
      order.items.some(item => item.id === opt.id)
    );

    // Calculate total spent and total quantity for the option
    const totalSpent = calculateTotalSpentOnIngredient(opt, optOrders);
    totalIngredientSpent += totalSpent;
    
    const totalQuantity = calculateTotalQuantity(opt, optOrders);
    totalIngredientQuantity += totalQuantity.quantity;
    totalIngredientQuantityAdjusted += totalQuantity.adjustedQuantity;

    // Prepare properties for calculating current and previous prices
    //const props = { ...rest, ing, opt, optOrders, optPrevWeekOrders, weekEnd };
    //Calculate the current price and the previous price
    const currentPrice = buildOptionCurrentPrice({optOrders, weekEnd, opt});
    const lastPrice = buildOptionPrevWeekPrice({optPrevWeekOrders, opt, ingUom});
    
    // Calculate price variation in value and percentage
    const priceVarValue = lastPrice.price && currentPrice.price && totalQuantity
    ? ((currentPrice.price * totalQuantity.quantity) - (lastPrice.price * totalQuantity.quantity))
    : 0;

    //Always compare based on previous period
    const priceVarPerc = lastPrice.price !== currentPrice.price
      ? ((currentPrice.price * 100) / (lastPrice.price) - 100)
      : 0;

    totalPriceVarValue += priceVarValue;
    
    return {
      id: opt.id,
      opt,
      supplierName: supplierMap[opt.supplierId] ? supplierMap[opt.supplierId].name : 'Unknown Supplier',
      uom: opt.uom,
      priceUom: currentPrice.unitprice ? `${currency(currentPrice.unitprice)}/${ingUom}` : '-',
      currentPrice: currentPrice.price ? currency(currentPrice.price) : '-',
      priceVarPerc: priceVarPerc ? priceVarPerc.toFixed(2) : (0).toFixed(2),
      priceVarValue: priceVarValue ? priceVarValue.toFixed(2) : (0).toFixed(2), 
      lastPrice: lastPrice.price ? currency(lastPrice.price) : currency(currentPrice.price),
      //Had to remove lastDate as it wasn't giving any value here and we had to pull all the orders from day 1
      //lastDate: lastPrice.date ? lastPrice.date.format('DD/MM') : '-',
      totalSpent: totalSpent,
      totalQuantity: totalQuantity.adjustedQuantity.toFixed(2),
      percentageOfTotal: ((totalSpent / totalOrderValue) * 100).toFixed(2),
    };
  });

  // Calculate overall percentage and average price for the ingredient
  const overallPercentageOfTotal = ((totalIngredientSpent / totalOrderValue) * 100).toFixed(2);
  const averagePrice = totalIngredientQuantityAdjusted > 0 ? currency(totalIngredientSpent / totalIngredientQuantityAdjusted) : (0).toFixed(2);
  const averagePriceVarPerc = totalIngredientQuantityAdjusted > 0 ? (((totalIngredientSpent + totalPriceVarValue) * 100 / totalIngredientSpent) - 100).toFixed(2)  : (0).toFixed(2);

  // Return data for the ingredient and its options
  return {
    ...ing,
    priceUom: `${averagePrice}/${ingUom}`,
    hideLink: true,
    opts: optsData, // All the options generated from the first return
    totalSpent: totalIngredientSpent ? currency(totalIngredientSpent) : (0).toFixed(2),
    totalQuantity: totalIngredientQuantityAdjusted ? totalIngredientQuantityAdjusted.toFixed(2) : (0).toFixed(2),
    percentageOfTotal: overallPercentageOfTotal,
    averagePrice: averagePrice,
    totalPriceVarValue: totalPriceVarValue ? totalPriceVarValue.toFixed(2) : (0).toFixed(2),
    averagePriceVarPerc: averagePriceVarPerc,
  };
};

const buildPricesList = ({ ings, optionList, costs, totalOrderValue, ...rest }) => {
  // First, build the detailed data for each ingredient
  const detailedIngData = ings.map(ing => buildIngOptionPrices({ ing, optionList, costs, totalOrderValue, ...rest }));

  // Aggregate data for each ingredient
  const aggregatedIngData = detailedIngData.map(ingData => {
    const totalSpent = ingData.opts.reduce((sum, opt) => sum + opt.totalSpent, 0);
    const percentageOfTotal = ((totalSpent / totalOrderValue) * 100).toFixed(2);

    return {
      ...ingData,
      totalSpent: currency(totalSpent),
      percentageOfTotal: percentageOfTotal
    };
  });

  // Calculate number of ingredients used where totalSpent > 0
  const usedIngredients = aggregatedIngData.filter(ing => parseFloat(ing.totalSpent) > 0);
  const numberOfUsedIngredients = usedIngredients.length;

  // Calculate number of supplier options used (based on the ingredient options' totalSpent)
  const usedSupplierOptions = aggregatedIngData.flatMap(ing => ing.opts)
    .filter(opt => parseFloat(opt.totalSpent) > 0);
  const numberOfUsedSupplierOptions = usedSupplierOptions.length;

  // Calculate total variance in £
  const totalVarianceValue = usedIngredients.reduce((sum, ing) => sum + parseFloat(ing.totalPriceVarValue), 0).toFixed(2);

  // Calculate variance percentage increase based on totalSpent
  const totalVariancePercentage = (((parseFloat(totalOrderValue) + parseFloat(totalVarianceValue)) * 100) / parseFloat(totalOrderValue) -100);

  // Sort the aggregated ingredient data by percentage of total
  const sortedAggregatedIngData = aggregatedIngData.sort((a, b) => b.totalSpent - a.totalSpent);

  // Create the final list while maintaining the sorted order
  const finalList = sortedAggregatedIngData.reduce((list, ingData) => {
    // Add the parent ingredient row
    list.push({
      id: ingData.id,
      name: ingData.name,
      supplierName: '',
      priceUom: ingData.priceUom,
      currentPrice: ingData.averagePrice,
      totalSpent: ingData.totalSpent,
      totalQuantity: ingData.totalQuantity,
      percentageOfTotal: ingData.percentageOfTotal,
      totalPriceVarValue: ingData.totalPriceVarValue,
      averagePrice: ingData.averagePrice,
      averagePriceVarPerc: ingData.averagePriceVarPerc,
      isParentRow: true,  // Identify as a parent row
      children: ingData.opts.map(opt => ({  // Children are the options
        id: opt.id,
        name: `${opt.opt.name}`, // Indent for options
        supplierName: opt.supplierName,
        priceUom: opt.priceUom,
        uom: opt.uom,
        currentPrice: opt.currentPrice,
        lastPrice: opt.lastPrice,
        //lastDate: opt.lastDate,
        priceVarPerc: opt.priceVarPerc,
        priceVarValue: opt.priceVarValue,
        totalSpent: currency(opt.totalSpent),
        totalQuantity: opt.totalQuantity,
        percentageOfTotal: opt.percentageOfTotal,
      }))
    });

    return list;
  }, []);

  return {
    finalList,
    numberOfUsedIngredients,
    numberOfUsedSupplierOptions,
    totalVarianceValue,
    totalVariancePercentage
  };
};

const ingredientTableHeaders = ({ openOptions }) => [
  { label: 'Ingredient & Supplier & Price/UOM', description: 'Ingredient & Supplier', field: 'name', type: 'text', width: 3 },
  { label: 'Pr./UOM', description: 'Ingredient avg. price/UOM & supplier ingredient price/UOM', type: 'text', field: 'priceUom', prefix:'£', width: 1 },
  { label: 'Sup. UOM', description: 'Supplier UOM', type: 'text', field: 'uom', width: 1 },
  { label: 'Price', description:'Current price', type: 'text', field: '', width: 1 },
  { label: 'LW price', description: 'Last week price', type: 'text', field: '', width: 1 },
  { label: 'Pr. Var %', description: 'Price variance in %', type: 'text', field: '', width: 1 },
  { label: 'T. Qty', description: 'Total Quantity', type: 'text', field: 'totalQuantity', width: 1 },
  { label: 'T. Spent £', description: 'Total Spent', type: 'numeric', field: 'totalSpent', prefix:'£', width: 1 },
  { label: 'T. Spent %', description: 'Total % supply chain', type: 'text', field: 'percentageOfTotal', suffix:'%', width: 1 },
  { label: 'Var. £', description: 'Toal variance in £', type: 'numeric', analytics: true, analyticsType: 'negative', field: 'totalPriceVarValue', prefix:'£', width: 1 },
  { label: 'Var. %', description: 'Toal variance in £', type: 'numeric', analytics: true, analyticsType: 'negative', field: 'averagePriceVarPerc', suffix:'%', width: 1 },
  { label: 'View', field: 'click', type: 'action', dynamicIcon: { isTrue: true, icon1: 'caretUp', icon2: 'caretDown' }, width: 1 }
]

const optionsTableHeaders = ({ accountsData, accountId }) => [
  { label: '', field: '', type: 'text', width: 1 },
  { label: 'Supplier', field: 'supplierName', type: 'text', width: 2 },
  { label: 'Pr./UOM', type: 'text', field: 'priceUom', prefix:'£', width: 1 },
  { label: 'Sup. UOM', type: 'text', field: 'uom', width: 1 },
  { label: 'Price', type: 'text', field: 'currentPrice', prefix:'£', width: 1 },
  { label: 'LW price', type: 'text', field: 'lastPrice', prefix:'£', width: 1 },
  { label: 'Pr. Var %', type: 'text', field: 'priceVarPerc', suffix:'%', width: 1 },
  { label: 'T. Qty', type: 'text', field: 'totalQuantity', width: 1 },
  { label: 'T. Spent £', type: 'text', field: 'totalSpent', prefix:'£', width: 1 },
  { label: 'T. Spent %', type: 'text', field: 'percentageOfTotal', suffix:'%', width: 1 },
  { label: 'Var. £', type: 'numeric', field: 'priceVarValue', prefix:'£', width: 1 },
  { label: 'Var. %', type: 'numeric', field: '', width: 1 },
  { label: 'View', type: 'action', link: `/${accountsData ? 'analytics' : `reports/${accountId}`}/procurement/`, icon: 'clipboard', width: 1 }
]

const IngredientsPricingTab = ({ accountId, optionList, ings, isOpen, setCategory, categoryOpt, accountsData, weekdates, costs }) => {
  //console.log(rest, ' IngredientPRicingTab')
  const [searchTerm, setSearchTerm] = useState('');

  // Filter ingredients based on the search term
  const filteredIngredients = useMemo(() => {
    return ings.filter(ing => 
      ing.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [ings, searchTerm]);

  const totalOrderValue = costs.weekProcurementTotal;
  // Fetch the price list and aggregate data
  const {
    finalList: priceList,
    numberOfUsedIngredients,
    numberOfUsedSupplierOptions,
    totalVarianceValue,
    totalVariancePercentage
  } = useMemo(() => {
    return buildPricesList({ ings: filteredIngredients, optionList, totalOrderValue, accountsData, supplierMap: accountsData.supplierMap, costs, weekdates });
  }, [filteredIngredients, optionList, totalOrderValue, accountsData, costs, weekdates]);

  const [openOptions, setOpenOptions] = useState({})
  
  useEffect(() => {
    const initialOptionsState = {};
    priceList.forEach(item => {
      if (item.isParentRow) {
        initialOptionsState[item.id] = false;  // All parent rows are initially closed
      }
    });
    setOpenOptions(initialOptionsState);
  }, [priceList]); 

  // Function to toggle open/close state of option rows
  const toggleOption = (id) => {
    setOpenOptions(prev => ({ ...prev, [id]: !prev[id] }));
    console.log('Toggling option for row:', id);
  };

  return (
    <>
      <Pane display='grid' gridTemplateColumns={'repeat(4, auto)'} gap={majorScale(2)} marginBottom={majorScale(2)}>
        <CustomCard
          title='Ingredients Used vs Total'
          data={numberOfUsedIngredients.toString()}
          prevData={ings.length.toString()}
        />
        <CustomCard
          title='Supplier options Used vs Total'
          data={numberOfUsedSupplierOptions.toString()}
          prevData={optionList.length.toString()}
        />
        <CustomCard
          title='Net purchase value'
          data={[costs.weekProcurementTotal.toString(), 'currency']}
        />
        <CustomCard
          title='Variance in £ and in %'
          data={`£${totalVarianceValue}`}
          prevData={`${totalVariancePercentage.toFixed(2)}%`}
        />
      </Pane>
      <Pane>
        <SearchInput
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search ingredients..."
          marginBottom={majorScale(1)}
        />
        <Block flex={`1 0 auto`} display="flex" height="500px" flexDirection="column" marginBottom={majorScale(2)}>
          <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
          <DataTable
            items={priceList}
            onClick={row => row.isParentRow ? toggleOption(row.id) : null}
            headers={ingredientTableHeaders({ openOptions })}
            childHeaders={optionsTableHeaders({ accountsData, accountId })}
            openOptions={openOptions} 
            listHeight={450}
          />
          </Pane>
        </Block>
      </Pane>
    </>
  );
}

export default IngredientsPricingTab;
