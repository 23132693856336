//TODO - This needs to be cleaned/refactor and reorganised somehow
//React
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';
import { Line, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import moment from 'moment';

//Components
import Page from '../../../components/Page/Page'
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import { CustomCard } from '../components/Recharts';

//Files
import { getAccountOptionOrdersFromDate, getIngredientStockUOM } from '../../../utils/functions';
import { createOptPrice, buildWeekOrders, buildYearOrders, buildPrevWeekorders, buildMonthOrders, buildThreeMonthOrders, buildIngOptSum, buildIngOptions } from '../../../utils/reports';
import { current } from '../../../utils/selectors';

const tableHeaders = [
  { label: 'Price', field: 'price', type: 'text', format: 'currency', width: 5 },
  { label: 'Date', field: 'date', type: 'text', width: 3 },
];

const createListPrice = (price, index) => ({
  ...price,
  date: price.date ? price.date.format('DD/MM/YYYY') : '-',
  id: index
})

const createPricesList = (option, orders) => {
  if (orders.length === 0) return [createListPrice({price: option.unitprice}, 0)];

  //orders = orders.slice(0, 9); // Removed the limit
  return orders.map((o, i) => createListPrice(createOptPrice(option, o), i))
}

const filterOrdersForPriceChange = (option, orders) => {
  if (!orders || !orders[0]) return [];

  let initPrice = createOptPrice(option, orders[0]);
  let index = 0;
  return orders.reduce((orders, order) => {
    if (index === 0) orders.push(order);
    else {
      const price = createOptPrice(option, order);
      if (price.unitprice !== initPrice.unitprice) {
        orders.push(order);
        initPrice = price;
      }
    }
    index++;
    return orders;
  }, [])
}

const buildOptionPrices = ( accounts, accountId, option ) => {
  if (!option) return null;
  const orders = _.reverse(getAccountOptionOrdersFromDate(accounts, accountId, option));
  return createPricesList(option, filterOrdersForPriceChange(option, orders));
}

// Utility function to check if a year has 53 weeks
const has53Weeks = (year) => {
  const lastDayOfYear = moment.utc(`${year}-12-31`);
  const is53Weeks = lastDayOfYear.isoWeeksInYear() === 53;
  //console.log(`Year ${year} has ${is53Weeks ? 53 : 52} weeks`);
  return is53Weeks;
};

// Function to generate year weeks data considering 53-week years
const generateYearWeeksData = (yearOffset = 0) => {
  const weeks = [];
  const year = moment.utc().startOf('year').add(yearOffset, 'years').year();
  const startOfYear = moment.utc().startOf('year').add(yearOffset, 'years').startOf('isoWeek');
  const totalWeeks = has53Weeks(year) ? 53 : 52;

  for (let i = 0; i < totalWeeks; i++) {
    const startOfWeek = startOfYear.clone().add(i, 'weeks');
    const endOfWeek = startOfWeek.clone().endOf('isoWeek');
    // Ensure week is attributed to the correct year
    const weekYear = startOfWeek.year() === year ? year : year + 1;
    weeks.push({
      week: i + 1,
      weekStart: startOfWeek.format('DD/MM/YYYY'),
      weekEnd: endOfWeek.format('DD/MM/YYYY'),
      year: weekYear,
      price: null, // Initialize as null
      volumeCurrentYear: 0,
      volumePreviousYear: 0,
    });
  }
  // Print the generated weeks to verify
  //console.log(`Generated Year Weeks Data (${startOfYear.year()}):`, weeks);
  return weeks;
};

// Function to map prices to weeks
const mapPricesToWeeks = (weeks, optionPricing, yearOffset = 0, currentYear = true) => {
  let lastKnownPrice = null;
  const currentWeek = moment.utc().isoWeek();

  optionPricing.forEach(item => {
    const itemDate = moment.utc(item.date, 'DD/MM/YYYY');
    weeks.forEach((weekData, index) => {
      const weekStart = moment.utc(weekData.weekStart, 'DD/MM/YYYY');
      const weekEnd = moment.utc(weekData.weekEnd, 'DD/MM/YYYY');

      if (itemDate.isBetween(weekStart, weekEnd, null, '[]') && itemDate.year() === weekStart.year()) {
        weeks[index].price = item.price;
        lastKnownPrice = item.price;
      }
    });
  });

  for (let i = 0; i < weeks.length; i++) {
    if (currentYear) {
      if (i + 1 <= currentWeek) {
        if (weeks[i].price === null) {
          weeks[i].price = lastKnownPrice;
        }
      } else {
        weeks[i].price = null;
      }
    } else {
      if (weeks[i].price === null) {
        weeks[i].price = lastKnownPrice;
      }
    }
  }
};

// Function to map volumes to weeks
const mapVolumesToWeeks = (weeks, orders, opt, ing, currentYear = true) => {
  //console.log(weeks, "weeksss")
  weeks.forEach((weekData, index) => {
    const weekStart = moment.utc(weekData.weekStart, 'DD/MM/YYYY').startOf('day');
    //console.log(weekStart, "weekSTARTs")
    const weekEnd = moment.utc(weekData.weekEnd, 'DD/MM/YYYY').endOf('day');
    //console.log(weekEnd, 'WEEKEND')

    // When mapping previous year data, we need to use the correct year's orders
    const volume = buildWeekOrders({ 
      orders, 
      weekStart, 
      weekEnd, 
      opt, 
      ing 
    });

    // Store volume in the appropriate field based on whether it's current or previous year
    if (currentYear) {
      weeks[index].volumeCurrentYear = volume;
    } else {
      weeks[index].volumePreviousYear = volume;
    }
  });
};

// Create chart data with correct year offsets and mapping functions
const createChartData = (option, optionPricing, orders, opt, ing, yearOffset = 0, currentYear = true) => {
  const yearWeeksData = generateYearWeeksData(yearOffset);
  
  // Filter orders for the specific year we're looking at
  const yearStart = moment.utc().startOf('year').add(yearOffset, 'years');
  const yearEnd = yearStart.clone().endOf('year');
  
  const yearOrders = orders.filter(order => {
    const orderDate = moment.utc(order.date);
    return orderDate.isBetween(yearStart, yearEnd, null, '[]');
  });

  mapPricesToWeeks(yearWeeksData, optionPricing, yearOffset, currentYear);
  mapVolumesToWeeks(yearWeeksData, yearOrders, opt, ing, currentYear);
  
  return yearWeeksData;
};

const buildOptionProcurement = ({ opt, ing, accounts, accountId, weekStart, weekEnd, supplierMap, ...rest }) => {
  if (!opt || !ing || !accounts || !accountId || !weekEnd || !weekStart) {
    console.warn('Missing parameters for buildOptionProcurement:', { opt, ing, accounts, accountId, weekEnd, weekStart });
    return null;
  }
  //console.log(supplierMap, 'SUPPLIERMAP')
  const orders = getAccountOptionOrdersFromDate(accounts, accountId, opt, moment(weekEnd));
  if (!orders) {
    console.warn('No orders found:', { accounts, accountId, opt, weekEnd });
    return null;
  }
  //console.log(orders, 'ORDERS')
  const props = { ...rest, orders, weekStart, weekEnd, opt, ing };
  //console.log('Building option procurement with props:', props);
  return {
    weekOrders: buildWeekOrders(props),
    prevWeekOrders: buildPrevWeekorders(props),
    monthOrders: buildMonthOrders(props),
    threeMonthOrders: buildThreeMonthOrders(props),
    yearOrders: buildYearOrders(props),
    id: opt.id,
    supplierName: supplierMap[opt.supplierId] ? supplierMap[opt.supplierId].name : 'Unknown Supplier',
  };
};

// Constructs procurement data for each option of a given ingredient.
const buildIngOptionsOrders = ({ ing, optionList, ...rest }) => {
  const ingsOpts = buildIngOptions(ing, optionList);
  //console.log(ingsOpts, 'INGOPT')
  const uom = getIngredientStockUOM(ing);
  const optProcurments = ingsOpts.map(opt => buildOptionProcurement({ opt, ing, uom, ...rest }));
  //console.log(optProcurments, 'OPTPROc')
  return buildIngOptSum(ing, uom, optProcurments);
}

// Builds a list of procurement data for all ingredients provided.
export const buildProcurementList = ({ ings, ...rest }) => {
  if (!ings || !Array.isArray(ings) || ings.length === 0) {
    console.warn('Invalid ingredient list provided:', { ings });
    return [];
  }
  return ings.reduce((list, ing) => {
    const ingData = buildIngOptionsOrders({ ing, ...rest });
    list.push({ ...ingData, opts: null });
    list = list.concat(ingData.opts);
    return list;
  }, []);
}

const tableHeadersTwo = [
  { label: 'Name', field: 'name', type: 'text', width: 3 },
  { label: 'Supplier', field: 'supplierName', type: 'text', width: 2 },
  { label: 'UOM', type: 'text', field: 'uom', width: 1 },
  { label: 'CW', type: 'text', field: 'weekOrders', width: 2 },
  { label: 'vs LW.', type: 'text', field: 'prevWeekOrders', width: 2 },
  { label: 'TM', type: 'text', field: 'monthOrders', width: 2 },
  { label: 'TL3M', type: 'text', field: 'threeMonthOrders', width: 2 },
  { label: 'TY', type: 'text', field: 'yearOrders', width: 2 },
];

const IngredientsPricingDetail = ({ accountId, accounts }) => {
  const { id } = useParams()
  const theme = useTheme();
  //console.log(accountId, accounts, 'ACCOUNTSSS')

  const navigate = useNavigate();
  const option = useSelector((state) => _.find(current(state, 'supplierOptions', accountId), { id: id }));
  
  //console.log(option, 'option')
  const ing = useSelector((state) => _.find(current(state, 'ingredients', accountId), { id: option ? option.ingredientId : null }));
  //console.log(ing, "ingredients")
  const supplier = useSelector((state) => _.find(current(state, 'suppliers', accountId), { id: option ? option.supplierId : null }));
  const suppliers = useSelector((state) => current(state, 'suppliers', accountId));
  const supplierMap = suppliers.reduce((map, supp) => {
    map[supp.id] = supp;
    return map;
  }, {});

  const uom = ing ? getIngredientStockUOM(ing) : null;
  const optionPricing = buildOptionPrices(accounts, accountId, option);
  const orders = getAccountOptionOrdersFromDate(accounts, accountId, option);
  //console.log(orders, "ORDERSSS")
  const title = `${option ? option.name : ''} - ${uom} - ${supplier ? supplier.name : ''}`;
  // In your IngredientsPricingDetail component
  const currentYearData = option ? createChartData(option, optionPricing, orders, option, ing, 0, true) : [];
  const previousYearData = option ? createChartData(option, optionPricing, orders, option, ing, -1, false) : [];

  const exit = () => {
    navigate(`${accounts ? '/analytics/costs/ingredients' : `/${accountId}/reports`}`, false, { tab: 'purchases' });
  }

  const startOfYear = moment.utc().startOf('year').add('years');
  //console.log(startOfYear)
  const yearStartOrderVolume = buildYearOrders({ orders, startOfYear, opt: option, ing });
  const yearOrderVolume = buildYearOrders({ orders, opt: option, ing });
  //console.log(yearOrderVolume)

  // Generate weekStart and weekEnd for procurement data
  const weekStart = moment.utc().startOf('isoWeek');
  const weekEnd = moment.utc().endOf('isoWeek');
  //console.log('Week Start:', weekStart);
  //console.log('Week End:', weekEnd);
  // Calculate previous week start and end
  const prevWeekStart = weekStart.clone().subtract(1, 'weeks');
  const prevWeekEnd = weekEnd.clone().subtract(1, 'weeks');

  const procurementList = buildProcurementList({
    ings: [ing],
    optionList: [option],
    accounts,
    accountId,
    weekStart: weekStart.format('YYYY-MM-DD'),
    weekEnd: weekEnd.format('YYYY-MM-DD'),
    prevWeekStart: prevWeekStart.format('YYYY-MM-DD'),
    prevWeekEnd: prevWeekEnd.format('YYYY-MM-DD'),
    supplierMap,
  });
  //console.log('Procurement List:', procurementList);

  // Extract current price and volume data
  const currentPrice = optionPricing.length > 0 ? optionPricing[0].price : 'N/A';
  const prevPrice = optionPricing.length > 1 ? optionPricing[1].price : 'N/A';
  const currentWeekVolume = procurementList.length > 1 ? procurementList[1].weekOrders : '0';
  const prevWeekVolume = procurementList.length > 1 ? procurementList[1].prevWeekOrders : '0';
  const currentYearVolume = procurementList.length > 1 ? procurementList[1].yearOrders : '0';
  const prevThirtyVolume = procurementList.length > 1 ? procurementList[1].monthOrders : '0';

  const currentWeek = moment().isoWeek();
  
  // Find the first week with a price for both years
  const firstCurrentYearPrice = currentYearData.findIndex(week => week.price !== null);
  const firstPrevYearPrice = previousYearData.findIndex(week => week.price !== null);

  // First, let's modify how we determine the starting point
  const currentYearStart = currentYearData.findIndex(week => 
    week.volumeCurrentYear > 0 || (week.price !== null && week.price !== 0)
  );

  const previousYearStart = previousYearData.findIndex(week => 
    week.volumeCurrentYear > 0 || (week.price !== null && week.price !== 0)
  );
  // Merge current and previous year data
  // Then update the combinedData creation
  const combinedData = currentYearData.map((currentWeek, index) => {
    const weekNum = currentWeek.week;
    const isAfterCurrentWeek = weekNum > moment().isoWeek();

    // Only show prices if we have actual data and it's not after current week
    const showCurrentYearPrice = index >= currentYearStart && !isAfterCurrentWeek;
    const showPreviousYearPrice = index >= previousYearStart;

    return {
      week: weekNum,
      volumeCurrentYear: currentWeek.volumeCurrentYear || 0,
      volumePreviousYear: previousYearData[index]?.volumePreviousYear || 0,
      priceCurrentYear: showCurrentYearPrice ? currentWeek.price : null,
      pricePreviousYear: showPreviousYearPrice ? previousYearData[index]?.price : null
    };
  });

  return (
    <React.Fragment>
    <Page>
      <Pane>
        <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          height={48}
          marginBottom={majorScale(2)}
          padding={majorScale(2)}
        >
          <IconWrapper
            onClick={exit}
            name="arrowLeft"
            appearance="clickable"
            marginRight={majorScale(4)}
          />
          <CustomHeading level="3">{title}</CustomHeading>
        </Block>

        <Pane display='grid' gridTemplateColumns={'repeat(3, auto)'} gap={majorScale(2)} marginY={majorScale(2)}>
          <CustomCard
            title='Current price'
            data={[currentPrice, 'currency']}
            infoData='Last price'
            prevData={[prevPrice, 'currency']}
          />
          <CustomCard
            title='This week Volume'
            data={[currentWeekVolume, 'number']}
            infoData='vs Last week'
            prevData={[prevWeekVolume, 'number']}
            
          />
          <CustomCard
            title='Current year'
            data={[currentYearVolume, 'number']}
            infoData='Last 30 days'
            prevData={[prevThirtyVolume, 'number']}
            
          />
        </Pane>
      
        <Block marginBottom={majorScale(2)} heigh="450px" padding={majorScale(2)}>
          <CustomHeading paddingBottom={majorScale(2)} level="3">Weekly purchased volume and Price trends</CustomHeading>
          {/*<ResponsiveContainer width="100%" height={400}>
              <ComposedChart data={currentYearData}>
                <XAxis dataKey="week" name="Weeks" />
                <YAxis yAxisId="left" orientation="left" stroke={theme.colors.secondary70} />
                <YAxis yAxisId="right" orientation="right" stroke={theme.colors.tertiary100} />
                <Tooltip />
                <Legend />
                <Bar yAxisId="left" dataKey="volumeCurrentYear" fill={theme.colors.secondary70} name="Weekly purchased volume" />
                <Line yAxisId="right" type="monotone" dataKey="price" stroke={theme.colors.tertiary100} strokeWidth={2} dot={false} name="Average weekly price" />
              </ComposedChart>
            </ResponsiveContainer>*/}
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart data={combinedData} barGap={0} barCategoryGap="15%">
                <XAxis 
                  dataKey="week" 
                  name="Weeks"
                  tickLine={false}
                  tick={{ fontFamily: 'Outfit, sans-serif', fontSize: '12px' }}
                  label={{ 
                    value: 'Week Number', 
                    position: 'bottom',
                    style: { 
                      fontFamily: 'Outfit, sans-serif',
                      fontSize: '12px',
                      marginTop: '10px'
                    } 
                  }}
                />
                <YAxis 
                  yAxisId="left" 
                  orientation="left" 
                  stroke="#637381"
                  tick={{ fontFamily: 'Outfit, sans-serif', fontSize: '12px' }}
                  label={{ 
                    value: 'Volume', 
                    angle: -90, 
                    position: 'insideLeft',
                    style: { 
                      fontFamily: 'Outfit, sans-serif',
                      fontSize: '12px'
                    }
                  }}
                  tickLine={false}
                />
                <YAxis 
                  yAxisId="right" 
                  orientation="right" 
                  stroke="#637381"
                  tick={{ fontFamily: 'Outfit, sans-serif', fontSize: '12px' }}
                  label={{ 
                    value: 'Price', 
                    angle: 90, 
                    position: 'insideRight',
                    style: { 
                      fontFamily: 'Outfit, sans-serif',
                      fontSize: '12px'
                    }
                  }}
                  tickLine={false}
                />
                <Tooltip 
                  contentStyle={{ 
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '12px',
                    backgroundColor: 'white',
                    border: '1px solid #f0f0f0',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }}
                  labelStyle={{
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '14px',
                    fontWeight: '500',
                    marginBottom: '5px'
                  }}
                />
                <Legend 
                  wrapperStyle={{
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '12px'
                  }}
                />
                
                {/* Current Year Volume */}
                <Bar 
                  yAxisId="left" 
                  dataKey="volumeCurrentYear" 
                  fill="#8884d8"
                  name="Year Volume"
                  radius={[8, 8, 0, 0]}
                  barSize={40}
                  maxBarSize={50}
                />
                
                {/* Previous Year Volume */}
                <Bar 
                  yAxisId="left" 
                  dataKey="volumePreviousYear" 
                  fill="#8884d8"
                  name="LW Volume"
                  radius={[8, 8, 0, 0]}
                  barSize={40}
                  maxBarSize={50}
                  opacity={0.3}
                />
                
                {/* Current Year Price */}
                <Line 
                  yAxisId="right" 
                  type="monotone" 
                  dataKey="priceCurrentYear" 
                  stroke="#ff7300"
                  strokeWidth={2} 
                  dot={false} 
                  name="Year Price"
                  connectNulls={true}
                />
                
                {/* Previous Year Price */}
                <Line 
                  yAxisId="right" 
                  type="monotone" 
                  dataKey="pricePreviousYear" 
                  stroke="#ff7300"
                  strokeWidth={2} 
                  dot={false} 
                  name="LY Price"
                  strokeDasharray="5 5"
                  opacity={0.5}
                  connectNulls={true}
                />
              </ComposedChart>
            </ResponsiveContainer>
        </Block>
      
        <Block  marginBottom={majorScale(2)} flex="1 0 auto" display="flex" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <CustomHeading level="3" padding={majorScale(3)}>Volume evolution for current year</CustomHeading>
              <DataTable
                items={procurementList}
                headers={tableHeadersTwo}
              />
            </Pane>
        </Block>
        <Pane>
          {_.isEmpty(optionPricing) && (
            <Block padding={majorScale(2)}>
              <Text>The requested ingredient option could not be found.</Text>
            </Block>
          )}
          {!_.isEmpty(optionPricing) && (
            <Block marginBottom={majorScale(2)} flex="1 0 auto" display="flex" flexDirection="column" height="420px">
                <CustomHeading level="3" padding={majorScale(3)}>Price evolution</CustomHeading>
                <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
                <DataTable
                  items={optionPricing}
                  headers={tableHeaders}
                  listHeight={300}
                />
                </Pane>
            </Block>
          )}
        </Pane>
      </Pane>
    </Page>
    </React.Fragment>
  )
}

export default IngredientsPricingDetail;
