import store from '../store/redux';
import firebase from '../store/firebase';
import _ from 'lodash';
import moment from 'moment';
import { TODAYC, TODAY } from './time';
import { accountTypes, LS_ACCOUNT_ID, orderStatuses, stockRecordingUnits } from './constants';
import { current } from './selectors';
import axios from 'axios';
import { createLightspeedSale } from './sales';
import { actions } from 'store/actions';
import { buildReceiptSalesByDate } from './receipts';

const dateStoreFormat = 'YYYY-MM-DD';
// #TODO server vars
export const FIREBASE_URL = 'https://us-central1-miseenplace-online.cloudfunctions.net';

//HELPER FUNCTIONS

// Helper function for field search functionality
const fieldsInclude = (item, fields, value) => {
  const loweredValue = value.toLowerCase();
  return fields.some(field => {
    if (item[field] === undefined || item[field] === null) {
      //console.warn(`Field "${field}" is undefined or null for item`, item);
      return false;
    }
    const fieldValue = item[field].toString().toLowerCase();
    return fieldValue.includes(loweredValue);
  });
};

// Generate a search function for text-based fields
export const generateSearchFieldsFn = (fields, value) => (item) => {
  if (typeof value !== 'string') {
    console.error('Value provided to generateSearchFieldsFn is not a string:', value);
    return false;
  }
  return fieldsInclude(item, fields, value);
};

// Retrieve the highest-level account accessible to the user
export const getTopLevelAccount = (accountId = false) => {
  const accounts = _.keyBy(store.getState().accounts, 'id');
  const acc = accountId || store.getState().currentAccount;
  if (!accounts[acc]) {
    return {};
  }

  const parents = accounts[acc].parents || [];
  let lastParent = accounts[acc];

  if (accounts[acc].type && accounts[acc].type === accountTypes.OWNER) {
    return accounts[acc];
  }
  for (let x = 0, ln = parents.length; x < ln; x++) {
    if (accounts[parents[x]]) {
      lastParent = accounts[parents[x]];
      if (accounts[parents[x]].type && accounts[parents[x]].type === accountTypes.OWNER) {
        return accounts[parents[x]];
      }
    }
  }

  return lastParent;
};


const belongsToHeadOffice = (account, headAccount, accounts) => {
  if (!headAccount.children) return false;

  for (let x = 0, ln = headAccount.children.length; x < ln; x++) {
    const childAcc = accounts[headAccount.children[x]];
    if (childAcc.children && childAcc.children.indexOf(account.id) > -1) return true;
  }

  return false;
}

export const getLocationAccounts = (accountId) => {
  const headAccount = getTopLevelAccount(accountId);
  const accounts = _.keyBy(store.getState().accounts, 'id');
  return _.filter(store.getState().accounts, (account) => (
    account.type === accountTypes.DEPARTMENT && account.id !== accountId && (belongsToHeadOffice(account, headAccount, accounts) )
  ));
}

// Retrieve all Supplier Options for a given Ingredient Id
export const getOptionsForIngredient = (ingredientId) => {
  return _.filter(current(store.getState(), 'supplierOptions'), { 'ingredientId': ingredientId });
};

// Retrieve an Ingredient by its Id
export const getIngredientById = (ingredientId) => {
  const ingredients = current(store.getState(), 'ingredients');
  const ingredient = _.find(ingredients, {
    'id': ingredientId
  });
  if (ingredient) {
    return ingredient;
  }
  return {
    type: 'notfound'
  };
};

// Retrieve a Recipe by its Id
// Check for modifier if recipe not found? -> bool check? #TODO
export const getRecipeById = (recipeId) => {
  // HACK*
  // workingAccount _might_ work here, but it's untested and unreliable. We'll 
  //  just find the account the recipe belongs to and return it that way
  let recipes = current(store.getState(), 'recipes', findAccountForObject({ itemId: recipeId, collection: 'recipes' }));
  const recipe = _.find(recipes, { 'id': recipeId });
  if (recipe) {
    return recipe; // Return the found recipe object
  }
  return null; // Return null if no recipe is found
};

export const getRecipeByPlu = (plu, accountId) => {
  const recipes = current(store.getState(), 'recipes', accountId);
  const recipe = _.find(recipes, {
    'plu': plu
  });
  if (recipe) {
    return recipe;
  }
};

// Retrieve an Ingredient by its Id
export const getModifierById = (id, accountId) => {
  const modifiers = current(store.getState(), 'modifiers', accountId);
  const modifier = _.find(modifiers, {
    id
  });
  if (modifier) {
    return modifier;
  }
  return {
    type: 'notfound'
  };
};

// Retrieve a modifier Ingredient by its plu
export const getModifierItemByPlu = (plu, accountId) => {
  const modifiers = current(store.getState(), 'modifiers', accountId);
  for (const modifier of modifiers) {
    const index = modifier.ingredients.findIndex(item => item.plu === plu);
    if (index > -1) {
      return { ...modifier.ingredients[index], modifierId: modifier.id, category: modifier.category };
    }
  }
};

// Calculate the net price for a Recipe
export const recipeNetPrice = (recipe) => {
  if (!recipe.menuprice) {
    return 0;
  }
  // Default VAT to 20%
  const vat = parseFloat((recipe.vat) ? recipe.vat : '20');
  const vatAmount = recipe.menuprice * (vat / (100 + vat));
  return recipe.menuprice - parseFloat(vatAmount.toFixed(2));
};

// Calculate the net price for a Recipe
export const modifierNetPrice = (modifier) => {
  if (!modifier.price) {
    return 0;
  }
  // Default VAT to 20%
  const vat = parseFloat((modifier.vat) ? modifier.vat : '20');
  const vatAmount = modifier.price * (vat / (100 + vat));
  return modifier.price - parseFloat(vatAmount.toFixed(2));
};

export const ingWastageAmount = (ing) => {
  const amount = ingredientUnitAmount(ing);
  return amount;
}

export const ingSaleAmount = (ing) => {
  // can it be a recipe? TODO

  // always smallest unit quanity
  // should this normalised qty
  return ing.amount ? parseFloat(ing.amount) : 0;
}

// quantity received or qauntity ordered
const getFinalQuantity = (order) => (
  order.quantityreceived ?
  parseFloat(order.quantityreceived) :
  order.quantity ?
  parseFloat(order.quantity) :
  0
)

export const ingOrderAmount = (ing) => {
  if (!ing.gramperportion && !ing.numperportion && ing.qtyReceivedNormal) {
    return parseFloat(ing.qtyReceivedNormal)
  }

  const gpp = ing.gramperportion ? parseInt(ing.gramperportion) : 1;
  const npp = ing.numperportion ? parseInt(ing.numperportion) : 1;
  const quantity = getFinalQuantity(ing);
  return quantity * npp * gpp;
}

//KEY function for the stocktake, drilling down to the last ingredient
export const recipeIngredientsUseAmount = (recipe, amount) => {
  let yieldAmount = 1;
  if (recipe.yield) {
    amount = parseFloat(amount) / parseInt(recipe.yield);
  }
  const ings = recipeIngsAmount(recipe, yieldAmount);
  return _.map(ings, (ing) => ({...ing, amount: ing.amount * parseFloat(amount) }));
}

const recipeIngsAmount = (recipe, qty = 1, parentRecipeId = null) => {
  //console.log(`Processing recipe: ${recipe.name}, ID: ${recipe.id}, Parent Recipe ID: ${parentRecipeId}`);
  return _.flatten(_.map(recipe.ingredients, (ing) => recipeIngAmount(ing, qty, recipe.id)));
}

const recipeIngAmount = (ing, qty = 1, parentRecipeId = null) => {
  if (!ing) {
    console.error(`Ingredient is null, Parent Recipe ID: ${parentRecipeId}`);
    return { ...ing, amount: 0 }; // Return 0 if ingredient is null
  }

  const ingQ = parseFloat(ing.quantity);

  if (ing.type && ing.type === 'subrecipe') {
    const subrecipe = getRecipeById(ing.id);

    if (!subrecipe) {
      console.error(`Subrecipe not found for id: ${ing.id}, Parent Recipe ID: ${parentRecipeId}`);
      return { ...ing, amount: 0 }; // Return 0 if subrecipe is not found
    }

    // Ensure yield is defined and a number
    const subRecipeYield = subrecipe.yield ? parseInt(subrecipe.yield) : 1;
    if (isNaN(subRecipeYield)) {
      console.error(`Subrecipe yield is not a valid number for id: ${ing.id}, Parent Recipe ID: ${parentRecipeId}`);
      return { ...ing, amount: 0 }; // Return 0 if yield is not a valid number
    }

    // Calculate the new quantity
    const subRecipeUsedAmount = ingQ / subRecipeYield;
    const newQty = qty * subRecipeUsedAmount;
    return recipeIngsAmount(subrecipe, newQty, parentRecipeId);
  }

  // Calculate the amount for a regular ingredient
  const amount = isNaN(ingQ) ? 0 : ingQ * qty;
  return { ...ing, amount };
}

// recipeId = haystack recipe - search subrecipes! / recpipe.id = needle recipe
// This function finds the total amount needed for a given recipe, drilling down into all sub-recipes recursively.
export const findSubRecipeAmount = (recipe, recipeId, qty = 1) => {
  //console.log(`Starting findSubRecipeAmount for recipeId: ${recipeId}, with qty: ${qty}`);
  // Direct match check: If the current recipe matches the target recipeId, return the passed quantity.
  if (recipe.id === recipeId) {
    //console.log(`Recipe ID match found for recipeId: ${recipeId}`);
    return parseFloat(qty);
  }

  let totalAmount = 0;
  const recipeFull = getRecipeById(recipeId); // Retrieve the full recipe by its ID.

  if (!recipeFull) {
    console.error(`Recipe with ID ${recipeId} not found.`);
    return totalAmount;
  }

  // Check for valid ingredients array.
  if (!recipeFull.ingredients || !Array.isArray(recipeFull.ingredients)) {
    //console.error(`No ingredients found for recipe with ID ${recipeId}.`);
    return totalAmount;
  }

  // Set the default yield value if undefined or zero, defaulting to 1 to avoid division errors.
  const effectiveYield = parseFloat(recipeFull.yield) > 0 ? parseFloat(recipeFull.yield) : 1;

  // Iterate over each ingredient in the recipe.
  for (const ing of recipeFull.ingredients) {
    //console.log(`Ingredient: ${ing.name}, Type: ${ing.type}, Required Qty: ${ing.quantity}, Recipe Yield: ${recipeFull.yield}`);
    if (ing.type && ing.type === 'subrecipe') {
      // Calculate the fraction of the sub-recipe required based on its quantity in the parent recipe.
      const requiredFraction = parseFloat(ing.quantity) * qty / effectiveYield;
      // Recursively find the amount for the sub-recipe.
      const subrecipeAmount = findSubRecipeAmount(recipe, ing.id, requiredFraction);
      //console.log(`Subrecipe ${ing.id} amount: ${subrecipeAmount}`);
      totalAmount += subrecipeAmount;
    }
  }
  // Output the total calculated amount for this recipe.
  //console.log(`Total amount for recipeId ${recipeFull.id} ${recipeId}: ${totalAmount}`);
  return totalAmount;
};


const safePortionNum = (num) => {
  num = parseFloat(num);
  if (!num || num === 0 || Number.isNaN(num)) return 1;

  return num
}

// Calculate the cost of an Ingredient used in a Recipe
const recipePriceCache = {};
export const ingRecipePrice = (ing) => {
  // removing the cache as it meant refresh was needed after updating an ingredient
  // if (recipePriceCache[ing.id]) {
  //   return recipePriceCache[ing.id];
  // }

  const options = _.compact(getOptionsForIngredient(ing.id));
  if (_.isEmpty(options)) {
    return 0;
  }

  const max = _.maxBy(options, (option) => {
    return option.unitprice / ( (option.numperportion || 1) * (option.gramperportion || 1) );
  });
  recipePriceCache[ing.id] = max
    ? (max.unitprice / (safePortionNum(max.numperportion) * safePortionNum(max.gramperportion)))
    : 0;
  return recipePriceCache[ing.id];
};

// DEPRECATED
// Return the UOM used for an Ingredient by its Supplier (now separated into Supplier Options)
export const ingredientSupplierUOM = (ing) => {
  return `${(ing.numperportion && ing.numperportion > 1) ? ing.numperportion + ' x ': ''}${ing.gramperportion} ${ing.recipeunit}`;
};

// Return the UOM used for an Ingredient by its Supplier
export const supplierOptionUOM = (option) => {
  if (option.uom) {
    return option.uom;
  }
  let units = 'units';
  if (option.ingredientId) {
    let ing = getIngredientById(option.ingredientId);
    units = ing.recipeunit || units;
  }
  return `${(option.numperportion && option.numperportion > 1) ? option.numperportion + ' x ' : ''}${option.gramperportion || 1} ${units}`;
};

const findModifierMax = (modifier) => {
  if (!modifier.multiselect) return 1;
  if (modifier.no_max || modifier.max > modifier.ingredients.length) return modifier.ingredients.length;
  return modifier.max;
}

export const modifierCost = (modifier) => {
  const details = getRecipeItemDetails(modifier);
  const cost = (modifier.type === 'subrecipe') ? recipeCosting(details) : ingRecipePrice(details);
  //console.log(`Modifier cost for ${modifier.id}: ${cost}`);
  return cost * parseFloat(modifier.quantity)
}

const modifierIngFinalCost = (item) => {
  const itemCost = parseFloat(modifierCost(item));
  //console.log(`Final cost for modifier ingredient ${item.id}: ${itemCost}`);
  return item.price ? itemCost - parseFloat(item.price) : itemCost;
}

const finalModifierIngredients = (ings) => {
  const finalIngredients = ings.map(ing => ({ ...ing, finalCost: modifierIngFinalCost(ing) }));
  //console.log(`Final modifier ingredients: ${JSON.stringify(finalIngredients)}`);
  return finalIngredients;
}

const calculateModifierThCost = (modifier) => {
  const max = findModifierMax(modifier);
  //console.log(`Max ingredients to consider for modifier ${modifier.id}: ${max}`);
  const items = _.reverse(_.sortBy(finalModifierIngredients(modifier.ingredients), 'finalCost')).slice(0, max);
  const totalModifierCost = _.sumBy(items, (item) => item.finalCost || 0);
  //console.log(`Total modifier cost for ${modifier.id}: ${totalModifierCost}`);
  return totalModifierCost;
}

// Calculate the total cost of a Recipe based on its Ingredients (+ subrecipes)
//withoutYield is if ze want to divide by the batch recipe, which is not needed if you want the total cost of the batch
//But needed if you want to reuse in another recipe
export const recipeCosting = (recipe, withoutYield = false, withModifiers = false) => {
  if (!recipe) {
    //console.error("Provided recipe is null or undefined.");
    return 0;  // Return a default cost of 0 if no recipe is provided
  }
  if (!recipe || !recipe.ingredients) {
    //console.log(`No ingredients found for recipe ${recipe.id || '[unknown ID]'}.`);
    return 0;  // Return a default cost of 0 if no ingredients are present
  }

  let totalcost = 0;
  for (let key in recipe.ingredients) {
    const ingUsed = recipe.ingredients[key];
    // Check if the ingredient is the recipe itself (self-reference)
    if (ingUsed.id === recipe.id) {
      throw new Error(`Recipe ID ${recipe.id} contains itself as a sub-recipe, which is not allowed.`);
    }
    if (ingUsed.type === 'modifier') {
      if (withModifiers) {
        const ingUsedCost = calculateModifierThCost(ingUsed);
        //console.log(`Modifier cost for ${ingUsed.id}: ${ingUsedCost}`);
        totalcost += ingUsedCost;
      } 
    }
    else if (ingUsed.type === 'subrecipe') {
      const subrecipe = getRecipeById(ingUsed.id);
      const ingUsedCost = recipeCosting(subrecipe) * ingUsed.quantity;
      totalcost += ingUsedCost;
    } else {
      const ingredient = getIngredientById(ingUsed.id);
      const ingUsedCost = ingRecipePrice(ingredient) * ingUsed.quantity;
      totalcost += ingUsedCost;
    }
  }
  if (withoutYield || !recipe.yield) {
    return totalcost;
  }
  return (totalcost / recipe.yield);
};

// Return the wastage of a Recipe as a decimal percentage
//  i.e. 10% is returned as 0.1
export const recipeWastePercentage = (recipe) => {
  const wastage = parseFloat(recipe.wastage) || 0;
  return wastage / 100;
};

// Determine the margin of profit of a given Recipe
export const recipeMargin = (recipe, withModifiers = false) => {
  if (!recipe.menuprice) {
    return 0;
  }
  const netprice = recipeNetPrice(recipe);
  const wastagePercent = recipeWastePercentage(recipe);
  // Full calculation is (netprice - (cost - (netprice * wastagePercent))) / netprice
  //  This simplifies to the equation below:
  const recipeCost = recipeCosting(recipe, false, withModifiers);
  const wastageCost = recipeCost * wastagePercent;
  const margin = 1 - ((recipeCost + wastageCost) / netprice);
  // const margin = 1 - ((recipeCosting(recipe) / netprice) + wastagePercent); //1 - ((recipeCosting(recipe) + (recipeCosting(recipe) * wastagePercent))  / netprice);
  // Convert to percentage, and round to the nearest decimal place
  return _.isNaN(margin) ? 0 : Math.round(margin * 1000) / 10;
};

// Determine the margin of profit of a given Recipe
export const modifierMargin = (modifier) => {
  if (!modifier.price) {
    return 0;
  }
  const netprice = modifierNetPrice(modifier);
  const recipe = modifier.type === 'subrecipe' ? getRecipeById(modifier.id) : null;
  const wastagePercent = recipe ? recipeWastePercentage(recipe) : 0;
  // Full calculation is (netprice - (cost - (netprice * wastagePercent))) / netprice
  //  This simplifies to the equation below:
  const recipeCost = modifier.price;
  const wastageCost = recipeCost * wastagePercent;
  const margin = 1 - ((recipeCost + wastageCost) / netprice);
  // const margin = 1 - ((recipeCosting(recipe) / netprice) + wastagePercent); //1 - ((recipeCosting(recipe) + (recipeCosting(recipe) * wastagePercent))  / netprice);
  // Convert to percentage, and round to the nearest decimal place
  return _.isNaN(margin) ? 0 : Math.round(margin * 1000) / 10;
};

// Calculate the yield per portion of a Recipe
export const recipeYieldPerPortion = (recipe) => {
  const recipeYield = recipe.yield || 1;
  const portion = recipe.portion || 1;
  return recipeYield / portion;
};

// Calculate the cost per portion of a Recipe
export const recipeCostPerPortion = (recipe) => {
  const portion = recipe.portion || 1;
  return recipeCosting(recipe) / portion;
};

const findRecipeIngredientAllergens = (ingredients, allergens = []) => {
  for (let x = 0, ln = ingredients.length; x < ln; x++) {
    let ingUsed = ingredients[x];
    if (ingUsed.type === 'modifier') {
      if (!_.isEmpty(ingUsed.ingredients)) {
        allergens = findRecipeIngredientAllergens(ingUsed.ingredients, allergens);
      }
    } else if (ingUsed.type === 'subrecipe') {
      let subrecipe = getRecipeById(ingUsed.id);
      if (subrecipe) {
        if (subrecipe.allergens) {
          allergens = _.concat(allergens, subrecipe.allergens);
        }
        if (subrecipe.ingredients) {
          allergens = findRecipeIngredientAllergens(subrecipe.ingredients, allergens);
        }
      } else {
        console.warn(`Subrecipe with ID ${ingUsed.id} is null or does not exist.`);
      }
    } else {
      let ingData = getIngredientById(ingUsed.id);
      if (ingData) {
        let ingAllergens = ingredientCompleteAllergens(ingData);
        if (ingAllergens) {
          allergens = _.concat(allergens, ingAllergens);
        }
      } else {
        console.warn(`Ingredient data for ID ${ingUsed.id} is null.`);
      }
    }
  }
  return allergens;
};

// Collate all allergens contained within a Recipe through its Ingredients or subrecipes
export const recipeIngredientAllergens = (recipe) => {
  let allergens = [];
  if (!recipe.ingredients) {
    return allergens;
  }
  allergens = findRecipeIngredientAllergens(recipe.ingredients, allergens);
  return _.uniq(allergens);
};

// Collate allergens for a given Ingredient within its Supplier Options
export const ingredientOptionAllergens = (ingredient) => {
  let allergens = [];
  if (!ingredient.id) {
    return allergens;
  }
  const options = getOptionsForIngredient(ingredient.id);
  if (_.isEmpty(options)) {
    return allergens;
  }
  for (let x = 0, ln = options.length; x < ln; x++) {
    if (options[x].allergens) {
      allergens = _.concat(allergens, options[x].allergens);
    }
  }
  return _.uniq(allergens);
};

// Return all allergens contained in an Ingredient, either directly or through
//  any of its Supplier Options
export const ingredientCompleteAllergens = (ingredient) => {
  let allergens = _.concat(ingredientOptionAllergens(ingredient), ingredient.allergens);
  return _.uniq(_.filter(allergens, Boolean)).sort();
};

// Return all allergens contained in a Recipe, either directly or through its
//  Ingredients or subrecipes
export const recipeCompleteAllergens = (recipe) => {
  if (!recipe || !recipe.allergens) {
    return [];
  }
  let allergens = _.concat(recipeIngredientAllergens(recipe), recipe.allergens);
  //console.log(allergens, 'FUNCTIONNS')
  return _.uniq(_.filter(allergens, Boolean)).sort();
};

// Retrieve the date of an Order
//  Defaults to the delivery date if available, the date marked on the order,
//  or as a last resort use the date the order was created
export const getOrderDate = (order) => {
  if (!_.isEmpty(order.deliveryDate)) {
    return moment.utc(order.deliveryDate, dateStoreFormat).toDate();
  }
  if (!_.isEmpty(order.date)) {
    return moment.utc(order.date, dateStoreFormat).toDate();
  }
  if (!_.isEmpty(order.created) && order.created.toDate) {
    return order.created.toDate();
  }
  return TODAYC;
}

export const getStocktakeMoment = (stocktake) => {
  return moment.utc(getStocktakeDate(stocktake), dateStoreFormat).utc();
}

// Retrieve the date of a StockTake
// Uses either the StockTake date, the date it was last modified, or as a last resort returns the current date
export const getStocktakeDate = (stocktake) => {
  //console.log('Stocktake:', stocktake);
  if (stocktake?.date) {
    //console.log('Using stocktake date:', stocktake.date);
    return stocktake.date instanceof Date ? stocktake.date : stocktake.date.toDate();
  }
  if (stocktake?.lastModified) {
    //console.log('Using lastModified:', stocktake.lastModified);
    return stocktake.lastModified instanceof Date ? stocktake.lastModified : stocktake.lastModified.toDate();
  }
  //console.log('Using TODAYC:', TODAYC);
  return TODAYC;
};

// Retrieve the date of a Wastage item
//  Uses either the date marked on the Wastage, the date it was created, or false
export const getWastageDate = (wastage) => {
  if (!_.isEmpty(wastage.date) && wastage.date.toDate) {
    return wastage.date.toDate();
  }
  if (!_.isEmpty(wastage.created) && wastage.created.toDate) {
    return wastage.created.toDate();
  }
  return false;
};

export const getTransferStringDate = (transfer) => {
  if (!_.isEmpty(transfer.date)) {
    // Assuming transfer.date is a string in a recognizable format (e.g., 'YYYY-MM-DD')
    return new Date(transfer.date);
  }
  if (!_.isEmpty(transfer.created) && transfer.created.toDate) {
    // Handling Firestore Timestamp if 'created' is used and is a Firestore Timestamp
    return transfer.created.toDate();
  }
  return false;
};

// Calculate the total cost of a StockTake by tallying up the worth of all 
//  Ingredients and Recipes recorded
export const calcStocktakeCost = (stocktake) => {
  if (_.isEmpty(stocktake) || (_.isEmpty(stocktake.ingredients) && _.isEmpty(stocktake.recipes))) {
    return 0;
  }
  const ingTotal = _.reduce(stocktake.ingredients, (sum, ing) => {
    return sum + (ing.worth || calcStocktakeIngredientCost(ing, stocktake.isDraft));
  }, 0);
  const recTotal = _.reduce(stocktake.recipes, (sum, recipe) => {
    return sum + (recipe.worth || calcStocktakeRecipeCost(recipe, stocktake.isDraft));
  }, 0);
  return ingTotal + recTotal;
};

export const ingredientUnitAmount = (ing) => {
  const {
    recipeunit,
    recordUOM
  } = ing;
  if (isNaN(ing.amount) || !ing.amount) {
    return 0;
  }

  if (_.startsWith(recordUOM, 'lot/')) {
    // Recorded number of "lots", so we take the numperportion and gramperportion 
    //  included to calculate the final cost
    let [, npp, gpp] = _.split(recordUOM, '/');
    return (parseFloat(ing.amount) * parseInt(npp) * parseInt(gpp));
  }
  else if (_.startsWith(recordUOM, 'unit/')) {
    // Recorded number of "units", so we take the gramperportion included to 
    //  calculate the final cost
    let [, gpp] = _.split(recordUOM, '/');
    return (parseFloat(ing.amount) * parseInt(gpp));
  }
  else if (recordUOM !== recipeunit && !_.isEmpty(recordUOM)) {
    // Recipe unit can only be g, ml, or unit, so if the recorded uom is different, we've
    //  measured either in kg or L; multiply the recipe price by 1000
    return (parseFloat(ing.amount) * 1000);
  }

  // If we're here, then recorded uom is the same as recipe uom (or undefined),
  //  so calculate as if it was in a recipe
  return parseFloat(ing.amount);
}

// Calculate the cost of an Ingredient recorded within a StockTake
const ingredientCostCache = {};
export const calcStocktakeIngredientCost = (ing, isDraft = false) => {
  const cacheKey = `${ing.id}-${ing.amount}-${ing.recordUOM}-${isDraft ? 'true' : 'false'}`;
  if (ingredientCostCache[cacheKey]) {
    return ingredientCostCache[cacheKey];
  }

  const ingDetails = (isDraft) ? getIngredientById(ing.id) : ing;
  let recipePrice = (isDraft) ? ingRecipePrice(ingDetails) : ing.recipeprice;

  // Log the ingredient details to check if recipeprice exists
  //console.log('Ingredient Details:', ingDetails);

  const ingAmount = ingredientUnitAmount(ing);

  if (!recipePrice) {
    recipePrice = ingRecipePrice(ingDetails);
  }

  // Log the final recipe price after fetching
  //console.log('Final Recipe Price:', recipePrice);

  // Ensure recipePrice is valid
  if (isNaN(recipePrice) || recipePrice === undefined || recipePrice === null) {
    console.error('Invalid Recipe Price:', recipePrice);
    return 0;
  }

  // Ensure ingAmount is valid
  if (isNaN(ingAmount) || ingAmount === undefined || ingAmount === null) {
    console.error('Invalid Ingredient Amount:', ingAmount);
    return 0;
  }
  // Log the final recipe price after fetching
  //console.log('Final ing Amount:', ingAmount);
  const calculatedCost = ingAmount * parseFloat(recipePrice);
  ingredientCostCache[cacheKey] = calculatedCost;

  // Log the calculated cost
  //console.log('Calculated Cost:', calculatedCost);

  return calculatedCost;
};

// Calculate the cost of a Recipe recorded within a StockTake
export const calcStocktakeRecipeCost = (recipe, isDraft = false) => {
  const recipeCost = (isDraft) ? recipeCosting(getRecipeById(recipe.id)) : recipe.recipeCosting;
  const {
    recordUOM,
    yieldDescription
  } = recipe;
  if (isNaN(recipe.amount) || !recipe.amount) {
    return 0;
  }

  if (recordUOM === 'batch') {
    // Recorded number of "batches", so we use the total recipe cost for all yield
    return (parseFloat(recipe.amount) * parseFloat(recipeCost) * (parseFloat(recipe.yield) || 1));
  } else if (!_.isEmpty(yieldDescription) && !_.isEmpty(recordUOM) && recordUOM !== yieldDescription) {
    // Yield UOM can only be g, ml, or unit, so if the recorded UOM is different, we've
    //  measured either in kg or L; multiply the cost by 1000
    return (parseFloat(recipe.amount) * parseFloat(recipeCost) * 1000);
  }
  // If we're here, then recorded uom is the same as yield uom (or either one is undefined),
  //  so calculate as normal
  return (parseFloat(recipe.amount) * parseFloat(recipeCost));
};

export const calcOrdersTotal = (orders) => orders.reduce((total, order) => {
  const orderCost = calcOrderTotal(order) || parseFloat(order.estimatedCost);
  return total + orderCost;
}, 0);

// Calculate the total cost of an Order
export const calcOrderTotal = (orderInfo) => {
  if (orderInfo.finalCost) {
    return parseFloat(orderInfo.finalCost);
  }

  let subtotal = 0;
  subtotal = _.reduce(orderInfo.items, (sum, ing) => {
    let qty = (ing.quantityreceived || ing.quantityreceived === 0) ? ing.quantityreceived : ing.quantity;
    let price = (ing.finalprice || ing.finalprice === 0) ? ing.finalprice : ing.unitprice;
    if (isNaN(qty) || !qty || isNaN(price) || !price) {
      return sum;
    }
    return sum + (parseFloat(qty) * parseFloat(price));
  }, 0);

  if (orderInfo.flat_discount) {
    subtotal -= parseFloat(orderInfo.flat_discount);
  }
  if (orderInfo.pc_discount) {
    subtotal -= (subtotal * (parseFloat(orderInfo.pc_discount) / 100));
  }
  if (orderInfo.delivery_charge) {
    subtotal += parseFloat(orderInfo.delivery_charge);
  }

  return subtotal;
};

const isBetweenDay = (day, start, end) => {
  //console.log("Checking if day is between - Day:", day.format(), "Start:", start.format(), "End:", end.format());
  start = start.format(dateStoreFormat);
  end = end.format(dateStoreFormat);
  return day.isSameOrAfter(start,'day') && day.isSameOrBefore(end, 'day');
}

const findDayNum = (day) => day.day() === 0 ? 7 : day.day();

export const getWeekDaySales = (accountId, periodStart, periodEnd, filters = null) => {
  periodStart = moment.utc(periodStart)
  periodEnd = moment.utc(periodEnd)
  const days = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {} };
  while(periodStart.isSameOrBefore(periodEnd, 'day')) {
    const items = getSalesForPeriod(accountId, periodStart, periodStart, true, filters);
    const day = { items, day: periodStart};
    days[findDayNum(periodStart)] = day;
    periodStart = moment.utc(periodStart).add(1,'day');
  }
  return days;
}

const updateRecipeSaleItem = (item, accountId, items, days = 1) => {
  let { recipeId, name, plu, margin, menuprice, netprice, cost, wastage, modifierId, type } = item;

  //Ensures that each sales item has a unique identifier in the items` object
  const id = `${recipeId}-${plu}`;
  const qty = item.qty / days

  if (wastage === undefined || isNaN(wastage)) {
    // If wastage was not stored in the sales item info, pull from the recipe
    let recipe = getRecipeById(recipeId);
    wastage = recipe.wastage || 0;
  }

  let wastagePercent = wastage / 100;

  // Where we initialise any default values for the items object
  if (!items[id]) {
    items[id] = {
      recipeId,
      name,
      plu,
      totalgross: 0,
      totalnet: 0,
      totalmargin: 0,
      totalcost: 0,
      totalwastagecost: 0,
      totalqty: 0,
      avgmargin: 0,
    };

    if (modifierId) {
      items[id].type = 'modifier';
      items[id].modifierId = modifierId;
    }
  }

  // Avoid using parseFloat too early
  // Totals
  items[id].totalgross += menuprice * qty;
  items[id].totalnet += netprice * qty;
  items[id].totalmargin += margin * qty;
  items[id].totalqty += qty;
  items[id].totalcost += cost * qty + (cost * wastagePercent * qty);
  items[id].totalwastagecost += cost * qty * wastagePercent;

  // Calculate average margin per item
  items[id].avgmargin = (items[id].totalmargin / items[id].totalqty);
  
  return items;
}

const calcPeriodDays = (periodStart, periodEnd) => {
  periodStart = moment.utc(periodStart)
  periodEnd = moment.utc(periodEnd)
  return 1 + periodEnd.diff(periodStart,'days');
}

export const findItemCatgeory = (accountId, item) => {
  const recipe = item.modifierId ?
    getModifierItemByPlu(item.plu, accountId) :
    getRecipeById(item.recipeId || item.id);
  const state = store.getState();
  const categories = item.modifierId ?
    current(state, 'modifierCategories', accountId) :
    current(state, 'recipeCategories', accountId);
  return findRecipeCategoryName(recipe, categories);
}

// Filters sales data based on various criteria such as categories or ids. 
// This is important for generating more specific reports (e.g., by category or product).
export const filterSalesItems = (accountId, items, filters) => {
  if (!filters) return items;

  return items.filter((item) => {
    // Check for 'ids' filter
    if (filters.ids?.length > 0 && !filters.ids.includes(item.recipeId)) {
      return false;
    }

    // Check for 'categories' filter
    if (filters.categories?.length > 0) {
      const category = findItemCatgeory(accountId, item);
      if (!category || !filters.categories.includes(category)) {
        return false;
      }
    }

    // If all conditions pass, include the item
    return true;
  });
};

export const getSalesForPeriod = (accountId, periodStart, periodEnd, daily = false, filters = null) => {
  periodStart = moment.utc(periodStart).startOf('day');
  periodEnd = moment.utc(periodEnd).endOf('day');

  const state = store.getState();

  const qualifyingSales = _.filter(current(state, 'sales', accountId), (salesData) => {
    const saleStart = moment(salesData.start, 'YYYY-MM-DD');
    const saleEnd = moment(salesData.end, 'YYYY-MM-DD').endOf('day');
    //console.log("Sale Start (UTC):", saleStart.format(), "Sale End (UTC):", saleEnd.format());
    return isBetweenDay(saleStart, periodStart, periodEnd) || isBetweenDay(saleEnd, periodStart, periodEnd);
  });

  const qualifyingReceipts = buildReceiptSalesByDate(accountId, periodStart, periodEnd);
  if (qualifyingReceipts) qualifyingSales.push(qualifyingReceipts);

  // Aggregate the data from any and all sales reports
  let items = {};

  for (let i = 0, ln = qualifyingSales.length; i < ln; i++) {
    const sale = qualifyingSales[i];
    // Convert sale start and sales end dates to UTC
    const saleStart = moment.utc(sale.start, 'YYYY-MM-DD');
    const saleEnd = moment.utc(sale.end, 'YYYY-MM-DD').endOf('day');

    // Calculate number of days if `daily` flag is true
    const days = daily ? calcPeriodDays(saleStart, saleEnd) : 1;

    // Get the list of sales items for the current sale
    let salesItems = qualifyingSales[i].items;
    // Filter the sales items based on `filters`
    salesItems = filterSalesItems(accountId, salesItems, filters);

    if (salesItems.length > 0) {
      for (let x = 0; x < salesItems.length; x++) {
        // Aggregate the sales data for each item
        items = updateRecipeSaleItem(salesItems[x], accountId, items, days);

      }
    }
  }

  return _.map(items, (item) => item);
};

export const getPreviousStocktakes = (accountId, date) => {
  return _.filter(current(store.getState(), 'stockTakes', accountId), (stock) => (
    !stock.isDraft &&
    moment.utc(stock.date, dateStoreFormat).isBefore(date)
  ));
};

export const getOrdersForPeriod = (accountId, periodStart, periodEnd, status = null) => {
  const orders = current(store.getState(), 'orders', accountId);
  return _.filter(orders, order => {
    const orderDate = moment.utc(order.deliveryDate || order.date, dateStoreFormat);
    //console.log(orderDate)
    return orderDate.isValid() && 
           orderDate.isBetween(periodStart, periodEnd, 'date', '[]') &&
           (status ? order.status === status : true);
  });
};

export const getOrdersFromDate = (accountId, date) => {
  return _.filter(current(store.getState(), 'orders', accountId), (order) => (
    (order.status === true) &&
    moment.utc(order.deliveryDate || order.date, dateStoreFormat).isSameOrBefore(date, 'date')
  ));
};

export const getOptionOrdersFromDate = (accountId, option, date) => {
  return _.reverse(
    _.sortBy(
      _.filter(current(store.getState(), 'orders', accountId), (order) => (
        (order.status === orderStatuses.RECEIVED) &&
        (order.items && order.items.findIndex(i => i.id === option.id) > -1) &&
        moment.utc(order.deliveryDate || order.date, dateStoreFormat).isSameOrBefore(date)
      )),
      (order) => moment.utc(order.deliveryDate || order.date, dateStoreFormat)
    )
  );
};

const getAccountsOptionOrdersFromDate = (accounts, option, date) => {
  date = moment.utc(date);
  return _.sortBy(
    (accounts.areas.reduce((orders, area) => {
      const areaOrders = getOptionOrdersFromDate(area.id, option, date);
      return orders.concat(areaOrders);
    }, [])),
    (order) => moment.utc(order.deliveryDate || order.date, dateStoreFormat)
  );
}

export const getAccountOptionOrdersFromDate = (accounts, accountId, option, date) => {
  date = moment.utc(date);
  return accounts ?
    getAccountsOptionOrdersFromDate(accounts, option, date) :
    getOptionOrdersFromDate(accountId, option, date);
}

export const getProcurementTotal = (weekProcurement, weekTransfers, reportIncidentals) => {
  return (
    calcOrdersTotal(weekProcurement)
    + castFloat(weekTransfers.transfersIn)
    - castFloat(weekTransfers.transfersOut)
    + castFloat(reportIncidentals.procurementPettyCash)
  );
}

export const getTransfersForPeriod = (accountId, periodStart, periodEnd) => {
  return _.filter(current(store.getState(), 'transfers', accountId), (obj) => (
    moment.utc(obj.date, dateStoreFormat).isBetween(periodStart, periodEnd, 'day', '[]')
  ));
};

export const calcTransferTotal = (transfers) => {
  return _.sumBy(transfers, (i) => i.totalCost, 0)
}

export const periodTransferCosts = (accountId, periodStart, periodEnd) => {
  const transfers = getTransfersForPeriod(accountId, periodStart, periodEnd);
  const transfersIn = calcTransferTotal(transfers.filter(t => t.transferType === 'IN'));
  const transfersOut = calcTransferTotal(transfers.filter(t => t.transferType === 'OUT'));
  return {
    transfersIn,
    transfersOut
  }
}

export const addRemoveTransfer = (amount, item, sum) => {
  if (item.transferType === 'IN') return sum + amount;

  return sum - amount;
}

export const calcTransfersLiveBalance = (transfers) => {
  return transfers.reduce((num, item) => addRemoveTransfer(ingWastageAmount(item), item, num), 0);
}

export const isValidNumeric = (value) => {
  return !isNaN(value);
};

export const getIngredientStockUOM = ({recipeunit}) => {
  if (recipeunit === 'g') return 'kg'
  else if (recipeunit === 'ml') return 'L';
  else return 'unit';
}

// Retrieve options available for uom when recording Ingredients
const ingredientUOMCache = {};
export const getIngredientUOMOptions = (ing) => {
  if (ingredientUOMCache[ing.id]) {
    return ingredientUOMCache[ing.id];
  }

  let uoms = [];
  if (ing.recipeunit === 'g') {
    uoms.push({ value: 'g', label: stockRecordingUnits['g'] });
    uoms.push({ value: 'kg', label: stockRecordingUnits['kg'] });
  } else if (ing.recipeunit === 'ml') {
    uoms.push({ value: 'ml', label: stockRecordingUnits['ml'] });
    uoms.push({ value: 'l', label: stockRecordingUnits['l'] });
  } else {
    uoms.push({ value: 'unit', label: stockRecordingUnits['unit'] });
  }

  let supplierOptions = getOptionsForIngredient(ing.id);
  for (let x in supplierOptions) {
    let opt = supplierOptions[x];
    let gpp = castInt(opt.gramperportion);
    let npp = castInt(opt.numperportion);
    // Add options for individual "units"
    if (gpp > 1) {
      if (ing.recipeunit === 'unit') {
        uoms.push({ value: `unit/${gpp}`, label: `Unit - ${gpp} ${stockRecordingUnits[ing.recipeunit]}` });
      }
      else if (gpp % 100 === 0 && gpp / 1000 >= 1) {
        // Supplier Option comes in (x) L or (x) Kg
        // Skip this option if it's 1L or 1Kg (these units are already provided)
        if (gpp / 1000 > 1) {
          uoms.push({ value: `unit/${gpp}`, label: `Unit - ${gpp / 1000} ${(ing.recipeunit === 'g') ? stockRecordingUnits['kg'] : stockRecordingUnits['l']}` });
        }
      }
      else {
        // Supplier Option is an odd size (ie. 500 or 950)
        // If numperportion is empty or 1, we can just use the Supplier UOM
        if (npp <= 1) {
          uoms.push({ value: `unit/${gpp}`, label: `Unit - ${opt.uom}`});
        }
        else {
          uoms.push({ value: `unit/${gpp}`, label: `Unit - ${gpp} ${stockRecordingUnits[ing.recipeunit]}` });
        }
      }
    }

    // If numperportion is greater than 1, then allow selection of a Lot
    if (npp > 1) {
      if (ing.recipeunit === 'unit') {
        uoms.push({ value: `lot/${npp}/${gpp}`, label: `Lot - ${npp} x ${gpp} ${stockRecordingUnits['unit']}` });
      }
      else if (gpp % 1000 === 0) {
        uoms.push({ value: `lot/${npp}/${gpp}`, label: `Lot - ${npp} x ${gpp / 1000} ${(ing.recipeunit === 'g') ? stockRecordingUnits['kg'] : stockRecordingUnits['l']}` });
      }
      else {
        uoms.push({ value: `lot/${npp}/${gpp}`, label: `Lot - ${npp} x ${gpp} ${stockRecordingUnits[ing.recipeunit]}` });
      }
    }
  }

  ingredientUOMCache[ing.id] = uoms;
  return uoms;
};

export const getRecipeUOMOptions = (recipe) => {
  let uoms = [];
  if (recipe.yieldDescription === 'g') {
    uoms.push('g', 'kg');
  } else if (recipe.yieldDescription === 'ml') {
    uoms.push('ml', 'l');
  } else {
    uoms.push('unit');
  }
  if (parseInt(recipe.yield) > 1) {
    uoms.push('batch');
  }

  return _.map(uoms, (unit) => ({
    value: unit,
    label: stockRecordingUnits[unit]
  }));
};

export const calcPercentage = (numerator, denominator, precision = 0) => {
  const floatNumerator = parseFloat(numerator) || 0;
  const floatDenominator = parseFloat(denominator) || 0;

  if (!floatDenominator) {
    return (0).toFixed(precision);
  }

  return _.round(
    (_.divide(floatNumerator, floatDenominator) || 0) * 100,
    precision
  ).toFixed(precision);
};

export const calcVariance = (first, second, precision = 1) => {
  const floatFirst = parseFloat(first);
  const floatSecond = parseFloat(second);

  if (!floatFirst || _.isNaN(floatFirst) || _.isNaN(floatSecond)) {
    return 0;
  }

  return calcPercentage(floatFirst - floatSecond, floatFirst, precision);
};

export const castInt = (num) => (parseInt(num) || 0);

export const castFloat = (num) => (parseFloat(num) || 0);

// display ing quantity and unit in large amount (Kg or L)
export const displayOrderIngredient = (ing) => {
  const { ingredient, normalQty } = ing;
  if (ingredient) {
    const { recipeunit } = ingredient;
    if (recipeunit === 'g' || recipeunit === 'ml') {
      return {
        ...ing,
        normalQty: denormalizeIngredientQuantity({ amount: normalQty, recipeunit }),
        displayRecordUOM: recipeunit === 'g' ? 'Kg' : 'L'
      }
    }
  }

  return ing;
}
//t
export const denormalizeIngredientUnit = (recipeunit) => {
  if (recipeunit === 'g' || recipeunit === 'ml') {
    return recipeunit === 'g' ? 'Kg' : 'L';
  }

  return recipeunit;
}

// rename to display quantity? TODO
export const denormalizeIngredientQuantity = ({ amount, recipeunit }) => {
  if (recipeunit === 'g' || recipeunit === 'ml') {
    return amount / 1000;
  }

  return amount;
}

// Normalize amounts to the recipe unit used for the ingredient
const normalizeIngredientCache = {};
export const normalizeIngredientQuantity = ({ amount, recordUOM, recipeunit }) => {
  const cacheKey = `${recipeunit}-${amount}-${recordUOM}`;
  if (normalizeIngredientCache[cacheKey]) {
    return normalizeIngredientCache[cacheKey];
  }

  let result;
  if (_.startsWith(recordUOM, 'lot/')) {
    // Recorded number of "lots", so we determine the full amount by using the
    //  included numperportion and gramperportion numbers
    let [, npp, gpp] = _.split(recordUOM, '/');
    result = (amount * parseInt(npp) * parseInt(gpp));
  }
  else if (_.startsWith(recordUOM, 'unit/')) {
    // Recorded number of "units", so we determine the full amount of recipe uoms
    //  in one unit using the included gramperportion number
    let [, gpp] = _.split(recordUOM, '/');
    result = (amount * parseInt(gpp));
  }
  else if (recordUOM !== recipeunit && !_.isEmpty(recordUOM)) {
    // Recipe unit can only be g, ml, or unit, so if the recorded uom is different,
    //  we've measured either in kg or L; multiply the amount accordingly
    result = (amount * 1000);
  }
  else {
    // If we're here, then the recorded uom is the same as the recipe uom (or undefined)
    result = amount;
  }
  normalizeIngredientCache[cacheKey] = result;
  return result;
};

export const normalizeRecipeQuantity = ({ amount, recordUOM, yield:recipeYield, yieldDescription }) => {
  if (recordUOM === 'batch') {
    // Recorded number of 'batches', so we use the total for all yield
    return (amount * (parseFloat(recipeYield) || 1));
  }
  else if (!_.isEmpty(yieldDescription) && !_.isEmpty(recordUOM) && recordUOM !== yieldDescription) {
    // Yield UOM can only be g, ml, or unit, so if the recorded UOM is different,
    //  we've measured either in kg or L; multiply by 1000
    return (amount * 1000);
  }
  // If we're here, then the recorded uom is the same as yield (or either one
  //  is undefined), so calculate as normal
  return amount;
};

export const findAccountForObject = ({ itemId, collection }) => {
  let col = store.getState()[collection];
  if (_.isEmpty(col)) {
    return false;
  }
  for (let key in col) {
    if (_.find(col[key], { 'id': itemId })) {
      return key;
    }
  }
  return false;
};

export const requestPdfLink = async (accountId, orderId) => {
  const response = await axios.get(`${FIREBASE_URL}/orderPdf`, {
    params: { accountId, orderId } 
  });
  if (response && response.data && response.data.url) {
    const { url, filename } = response.data;
    if (url && filename) {
      const pdf = encodeURI(`${FIREBASE_URL}/downloadFile?url=${url}&filename=${filename}`);
      window.open(pdf);
    }
  }
}

export const requestInvoiceUploadUrl = async (filename) => {
  return await firebase.storage.ref(filename).getDownloadURL();
}

export const requestInvoiceUploadImage = async (filename) => {
  const url = await requestInvoiceUploadUrl(filename);
  if (url) window.open(url, '_blank');
}

export const sendInvoiceUrlToNanonets = async (filename) => {
  console.log(filename,'filename start')
  const url = await requestInvoiceUploadUrl(filename);
  console.log(url,'url collected')
  if (url) {
    // const form_data = {
    //   "data": [
    //     {
    //       "filename": url,
    //       "object": [
    //         {"name":"Invoice New", "ocr_text":"text inside the bounding box", "bndbox": {"xmin": 1,"ymin": 1,"xmax": 100, "ymax": 100}}
    //       ]
    //     }
    //   ],
    //   "urls": [url]
    // }
    // console.log('start call')
    // await axios({
    //   url: 'https://app.nanonets.com/api/v2/OCR/Model/2ec226fa-0ad8-41f7-a270-da8abfcca9c3/UploadUrls/',
    //   method: 'POST',
    //   headers: {
    //     'Authorization' : 'Basic ' + Buffer.from('aG8nHiwqqP7hU5GA9Nlir8z8d0Z7TCpS' + ':').toString('base64'),
    //     'Content-Type': "application/json",
    //     "Access-Control-Allow-Origin": "*"
    //   },
    //   body: JSON.stringify(form_data),
    // });

    // var data = JSON.stringify({
    //   "data": [
    //     {
    //       "filename": url,
    //       "object": [
    //         {
    //           "name": "category1",
    //           "bndbox": {
    //             "xmin": 1,
    //             "ymin": 1,
    //             "xmax": 100,
    //             "ymax": 100
    //           },
    //           "ocr_text" : "text inside the bounding box",
    //         },
    //         {
    //           "name": "category2",
    //           "bndbox": {
    //             "xmin": 1,
    //             "ymin": 1,
    //             "xmax": 100,
    //             "ymax": 100
    //           },
    //           "ocr_text" : "text inside the bounding box",
    //         }
    //       ]
    //     }
    //   ],
    //   "urls": [
    //     url
    //   ]
    // });
    
    // var xhr = new XMLHttpRequest();
    // xhr.withCredentials = true;
    
    // xhr.addEventListener("readystatechange", function () {
    //   if (this.readyState === 4) {
    //     console.log(this.responseText);
    //   }
    // });
    
    // xhr.open("POST", 'https://app.nanonets.com/api/v2/OCR/Model/2ec226fa-0ad8-41f7-a270-da8abfcca9c3/UploadUrls/',);
    // xhr.setRequestHeader("Content-Type", "application/json");
    // xhr.setRequestHeader("authorization", "Basic " + btoa("aG8nHiwqqP7hU5GA9Nlir8z8d0Z7TCpS:"));
    
    // xhr.send(data);

    var data = 'urls=' + url;

    var xhr = new XMLHttpRequest();

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === this.DONE) {
            console.log(this.responseText);
        }
    });

    xhr.open("POST", "https://app.nanonets.com/api/v2/OCR/Model/2ec226fa-0ad8-41f7-a270-da8abfcca9c3/LabelUrls/?async=false");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("authorization", "Basic " + btoa("BJamWb3DYyyRiU7jVxFldHXF2XWkNl4W:"));


    xhr.send(data);
    
  }
}

export const requestSalesLink = async (accountId, from, to) => {
  const response = await axios.get(`${FIREBASE_URL}/requestSales`, {
    params: { accountId, from, to, save: false } 
  });
  if (response && response.data) {
    const { sales, date } = response.data;
    const salesDataCreate = createLightspeedSale(sales, date, accountId, false);

    if (salesDataCreate) {
      store.dispatch(actions.sales.addSales(accountId, salesDataCreate))
      store.dispatch(actions.appMessageSuccess('Lightspeed Sales Added'))
    }
  }
}

const listspeedUrl = 'https://lightspeedapis.com/resto/oauth2/v1/authorize?response_type=code&client_id=0G59S6fccu4mmgSFb4LLLdM824pQTntt&code_challenge=wcX9QdmZJgLtVWOULB1UnTcqsi3SMqN5HOuZxzfC8GQ&code_challenge_method=S256'

export const requestLightspeedAccess = async (accountId) => {
  localStorage.setItem(LS_ACCOUNT_ID, accountId)
  window.open(listspeedUrl, '_blank');
}

export const requestLightspeedAccessTokens = async (accountId, code) => {
  await axios.get(`${FIREBASE_URL}/requestLightspeedTokens`, {
    params: { accountId, code } 
  });
}

export const createMomentTime = (time = '22:00', date = moment.utc()) => {
  const [hours, minutes] = time.split(':');
  return date.hours(hours).minutes(minutes);
}

export const requestAccountSales = async (accountId) => {
  const accounts =  store.getState().accounts;
  
  const account = accounts[accountId];
  if (!account || account.type !== accountTypes.DEPARTMENT) {
    return;
  }

  if (!account.has_lightspeed) {
    return;
  }

  const openTime = account.opening_time || '08:00';
  const time = createMomentTime(openTime)
  let from = moment.utc(time)
  if (moment.utc().isBefore(from)) {
    from.subtract(1,'day')
  }
  from = from.format("YYYY-MM-DDTHH:mm")
  const to = moment.utc().format("YYYY-MM-DDTHH:mm");

  const response = await axios.get(`${FIREBASE_URL}/requestSales`, {
    params: { accountId, from, to, save: false, go: true } 
  });
  if (response && response.data) {
    const { sales, date } = response.data;
    const salesData = createLightspeedSale(sales, date, accountId);
    store.dispatch(actions.sales.saleLoadSuccess(salesData, accountId))
    // store.dispatch(actions.appMessageSuccess('Lightspeed Sales Updated'))
  }
}

export const createOrderNumber = (orderInfo, account) => {
  if (orderInfo.type === 'CREDIT') return null;

  return `${account && account.location_prefix ? account.location_prefix + '_' : ''}${orderInfo.orderNumber}`
}

export const getMultiselectString = (modifier) => {
  if (!modifier.multiselect) return 'Select 1';

  return `Min: ${modifier.min} - Max: ${modifier.no_max ? 'No Max' : modifier.max}`;
}

export const getRecipeItemDetails = (item, accountId) => {
  if (!item.type) return getIngredientById(item.id);

  if (item.type === 'modifier') return getModifierById(item.id, accountId);

  return getRecipeById(item.id);
}

//This function is used to get items details in a recipe
//This needs to be updated to look for id -> then pull correct information
export const getRecipeCalculatedFields = (item, itemDetails) => {
  if (item.type === 'modifier') return {
    name: itemDetails.name,
    quantity: itemDetails.quantity,
    multiselect: getMultiselectString(itemDetails) 
  };

  return {
    name: itemDetails.name,
    recipeUnit: (item.type === 'subrecipe') ? itemDetails.yieldDescription ? itemDetails.yieldDescription : 'portion / g / ml' : itemDetails.recipeunit,
    cost: item.quantity * ((item.type === 'subrecipe') ? recipeCosting(itemDetails) : ingRecipePrice(itemDetails))
  };
}

export const getSalesRecipeItems = (sales, accountId) => {
  const state = store.getState();
  const recipeMap = _.keyBy(current(state, 'recipes', accountId), 'id');
  const recipeCategories = current(state, 'recipeCategories', accountId);
  const modifierCategories = current(state, 'modifierCategories', accountId);
  return (
    _.map(
      sales,
      (item) => {
        if (item.type === 'modifier') {
          const modifier = getModifierItemByPlu(item.plu, accountId);
          return {
            ...modifier,
            ...item,
            category: modifier ? findRecipeCategoryName(modifier, modifierCategories) : item.category
          }
        }
        if (!recipeMap[item.recipeId]) {
          return item;
        }
        return {
          ...recipeMap[item.recipeId],
          ...item,
          category: findRecipeCategoryName(recipeMap[item.recipeId], recipeCategories)
        };
      }
    )
  );
}

 // Concat recipe and modifier categories
 export const getAllCategories = (accountId) => {
   const state = store.getState();
   return current(state, 'recipeCategories', accountId).concat(current(state, 'modifierCategories', accountId))
}

export const getModifierRecipes = (modifier, accountId) => (
  _.filter(current(store.getState(), 'recipes', accountId), (recipe) => {
    if (recipe.ingredients && recipe.ingredients.findIndex(i => i.type === 'modifier' && i.id === modifier.id) > -1) {
      return true;
    }
    if (recipe.addOns && recipe.addOns.findIndex(a => a.id === modifier.id) > -1) return true;
  })
);

export const reopenStocktake = (accountId, stockTake) => {
  if (stockTake) {
    store.dispatch(actions.stockTake.updateStockTake(accountId, {...stockTake, isDraft: true, cost: null}));
  }
}

// Used primarily by Datatable
export const filterItems = (items, filters) => {
  return _.filter(items, (item) => {
    for (let key in filters) {
      //console.log(`Filtering by key: ${key} with value: ${filters[key]}`);
      if (typeof filters[key] === 'string') {
        if (_.has(item, key)) {
          if (_.isArray(item[key])) {
            if (item[key].indexOf(filters[key]) === -1) {
              return false;
            }
          }
          else if (item[key] !== filters[key]) {
            return false;
          }
        }
      }
      else if (typeof filters[key] === 'function') {
        if (!filters[key](item)) {
          return false;
        }
      }
    }
    //console.log('Item passed the filters:', item);
    return true;
  });
};

export const findRecipeCategoryName = (recipe, categories) => {
  return recipe?.categoryId ?
    categories.find(c =>  c.id ===  recipe.categoryId)?.name :
    recipe?.category
}

export const getCategoryNameById = (categoryId, recipeCategories) => {
  const category = recipeCategories.find(c => c.id === categoryId);
  return category ? category.name : 'Unknown Category';
};

export const buildRecipeCategory = (recipe, categories) => {
  const category = findRecipeCategoryName(recipe, categories)
  return {
    ...recipe,
    category
  }
}

export const buildUserRecipeList = (state) => {
  return _.flatMap(state.recipes);
}

export const userHasUnreadMessages = (state) => {
  if (state.loading) {
    return false;
  }

  const message = _.last(_.sortBy(current(state, 'messages'), ['created']));
  if (message && message.fromId !== state.profile?.id) {
    const lastSeen = state.messagesSeenTimes[state.currentAccount] && state.messagesSeenTimes[state.currentAccount][0] ?
      state.messagesSeenTimes[state.currentAccount][0][state.profile?.id] :
      null;

    if (!lastSeen || lastSeen < message.created) return true;
  }
}

export const findRecipeAreaFromLocation = (recipe, state) => {
  const department = _.findKey(state.recipes, (dep) => dep.findIndex(r => r.id === recipe.id) > -1);
  const location = state.accounts[state.currentAccount];
  const area = _.find(state.accounts, (ac) => {
    if ((location?.children?.indexOf(ac.id) > -1) && ac.departments?.indexOf(department) > -1)  {
      return true;
    }
  });
  
  return area;
}
